<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-03-20 20:49:33
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-27 18:01:48
 6c1b315f4d0bc71188b0eda376852797
 * @FilePath: /AIGo.work/src/App.vue
-->
<template>
  <div id="app">
    <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />

    <Loading v-if="loading"
      :loading="loading">
    </Loading>
    <!-- <button class="test" @click="testtttttt">test</button> -->
    <updateDialog :visible.sync="updateDialogVisible"></updateDialog>
  </div>
</template>
<script>
import Loading from '@/components/directiveLoading/Loading.vue'
import Fingerprint2 from 'fingerprintjs2'
import { mapState } from 'vuex'
export default {
  components: { Loading },
    data() {
        return {
            updateDialogVisible: false,
            isLargeScreen: false,
        }
    },
  computed: {
    ...mapState(['loading', 'keepAlivePage'])
  },

  created() {
    // const manifestPath = '/manifest.json';
    // fetch(manifestPath)
    // .then(response => {
    //     if (!response.ok) {
    //     throw new Error('Network response was not ok ' + response.statusText);
    //     }
    //     return response.json();
    // })
    // .then(data => {
    //     alert('Manifest data:', data);
    //     // 这里可以处理manifest数据，比如显示在页面上等
    // })
    // .catch(error => {
    //     alert('Error reading manifest:', error);
    // });
    this.updateDialogVisible = false
    let updateVersionDate = localStorage.getItem('updateVersionDate')
    let aigoFileVersion = localStorage.getItem('aigoFileVersion')
    const oneWeek = 7 * 24 * 60 * 60 * 1000; // 一周的毫秒数
    if((Date.now() - updateVersionDate < oneWeek)&&aigoFileVersion!=window.aigoFileVersion){
        // this.updateDialogVisible = true // 手动刷新
        window.localStorage.clear()
        window.location.reload(true) // 自动刷新
    }
    if(aigoFileVersion!=window.aigoFileVersion){
        localStorage.setItem('aigoFileVersion', window.aigoFileVersion)
        localStorage.setItem('updateVersionDate', Date.now())
    }
    // console.log('当前环境', process.env.NODE_ENV);
    // if (this._isMobile()) {
    //   window.localStorage.setItem('phone', true)
    // } else {
    //   window.localStorage.setItem('phone', false)
    // }
    this.handleResize(); // 组件创建时检查一次
    window.addEventListener('resize', this.handleResize);
    Fingerprint2.get(function (components) {
      const values = components.map(function (component, index) {
        if (index === 0) {
          return component.value.replace(/\bNetType\/\w+\b/, '')
        }
        return component.value
      })
      // 生成最终id murmur
      const murmur = Fingerprint2.x64hash128(values.join(''), 31)
      // console.log(murmur, 'app')
      // 6c1b315f4d0bc71188b0eda376852797
      window.localStorage.setItem('murmur', murmur)
      // store.commit('SET_MURMUR', murmur)
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  async mounted(){
    // if(this.$store.state.terminal=='app'){
    //     this.plusReady()
    // }
  },
  methods: {
    plusReady(){
        // 监听“返回”按钮事件
        plus.key.addEventListener("backbutton",function(e){
            alert("keydown: "+e.keyCode);
        },false);
    },
    getBottomBarHeight() {
        const windowHeight = window.innerHeight;
        const viewportHeight = document.documentElement.clientHeight;
        return windowHeight - viewportHeight; // 底部操作栏的高度
    },
    platform() {
        const ua = navigator.userAgent.toLowerCase();
        const isWeixin = ua.indexOf('micromessenger') !== -1;
        const isInApp = /(^|;\s)app\//.test(ua);
        if (isWeixin) {
            if (window.__wxjs_environment === 'miniprogram') {
                return 'wxapp';
            } else {
                return 'wxh5';
            }
        } else {
            if (!isInApp) {
                return 'browser';
            } else {
                return 'app';
            }
        }
    },
    handleResize() {
      this.isLargeScreen = window.innerWidth < 1024; // 假设1024px以上是“大屏幕”
      window.localStorage.setItem('phone', this.isLargeScreen)
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    },
    testtttttt(){
        // document.getElementsByTagName("body")[0].scrollTop = 0
        // document.getElementsByTagName("html")[0].scrollTop = 0
        
        // document.getElementsByTagName("body")[0].style.height = "100%"
        // document.getElementsByTagName("html")[0].style.height = "100%"

        // console.log( document.querySelector('.rightWrap') )
        // document.querySelector('.rightWrap').scrollTop = 0
        
        // document.querySelector('.rightWrap').style.height = "100%"
        // document.querySelector('.rightWrap').style.top = 0

        // document.querySelector('.chats_content').style.height = "100%"
        // document.querySelector('.chats_content').style.top = 0
        // document.querySelector('.chats_content').scrollTop = 0

        // document.querySelector('.right').style.height = "100%"
        // document.querySelector('.right').style.top = 0
        // document.querySelector('.right').scrollTop = 0
        
        // document.querySelector('.contents').style.height = "100%"
        // document.querySelector('.contents').style.top = 0
        // document.querySelector('.contents').scrollTop = 0
        
    }
  }
}
</script>
<style lang="scss">
body {
  margin: 0;
  padding: 0;
}
ol {
  // margin-block-start: 0em;
  // margin-block-end: 0em;
  // padding-inline-start: 0px;
}
p {
  // margin: 0;
  // padding: 0 !important;
  // margin-block-start: 0 !important;
  // margin-block-end: 0 !important;
}
.test{
    position: fixed;
    left: 5px;
    top: 250px;
    z-index: 9999999;
}

</style>
