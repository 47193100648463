<template>
   <div>
        <PayResultCheck :visible.sync = "PayResultCheckVisible"></PayResultCheck>
   </div>
</template>

<script>
import REQUEST from '@/api/http';
import { mapState } from 'vuex'
import { getQueryStrByUrl, getQueryString, isMobile, isWx, processUrl } from '@/utils';
import PayResultCheck from './PayResultCheck.vue';

export default {
components: {PayResultCheck  },
data() {
   return {
    PayResultCheckVisible:false,
    orgId:null,

    //升级套餐的时候用的，调创建订单接口要传这个
    refId:null,

    couponId:null,//券id， 兑换券和优惠券都需要这个参数

    fdCouponTag:null,//兑换码，使用兑换券需要传这个参数
    shareId: ""
   }
},
computed:{
    ...mapState([ ]),
    isWx(){
        return isWx()
    },
    mobile(){
        return isMobile()
    },

},
async mounted() {

},
methods: {
    startPay(pid, payNumber, orgId){
        this.orgId = orgId
        var payType
        if(this.isWx){
            payType = 'wxpay'
            //跳转微信授权页
            //跳授权页

            //现把要买的产品的信息保存在本地，授权完跳转过来后再取出来，并移除

            // this.curProductItem.payNumber = payNumber
            var obj = {id:pid, payNumber:payNumber}
            if(this.refId){
                obj.refId= this.refId
            }

            if(this.couponId){
                obj.couponId = this.couponId
            }
            if(this.fdCouponTag){
                obj.fdCouponTag = this.fdCouponTag
            }
            if(this.shareId){
                obj.shareId = this.shareId
            }

            var jsonStr = JSON.stringify(obj)
            window.localStorage.setItem("wxPayProductInfo", jsonStr)

            // var url_no_query = location.href.split("?")[0]//去掉后面的query参数，因为如果有code，微信还是会再加一个code，而不是覆盖
            var url_no_query = processUrl(location.href, "code")//如果url后面有code参数，去掉code参数，
            var redirect_uri = encodeURIComponent(url_no_query)
            location.href=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxcf27a05a564d021d&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`

        }else{
            payType = 'alipay'
            this.createOrder({
                payType, 
                productId:pid, 
                payNumber, 
                couponId:this.couponId ? this.couponId : null, 
                fdCouponTag:this.fdCouponTag ? this.fdCouponTag : null,
                shareId:this.shareId ? this.shareId : null, 
            })
        }
    },
    setRefId(refId){
        this.refId = refId
    },
    async createOrder({payType, productId, payNumber, code, couponId, fdCouponTag, shareId}){
            // var res = await this.$https('createOrder', {payNumber:payNumber, payType:payType, productId:productId, code:code})

            var param = {}
            if(this.orgId){
                param.orgId = this.orgId
            }
            // console.log("param", param);

            var obj = {payNumber:payNumber, payType:payType, productId:productId, code:code}
            if(this.refId){
                obj.refId= this.refId
            }
            if(couponId){
                obj.couponId = couponId
            }
            if(fdCouponTag){
                obj.fdCouponTag = fdCouponTag
            }
            if(shareId){
                obj.shareId = shareId
            }
            var res = await REQUEST.POST_URL_QUERY("createOrder", obj, true, param)

            if (res.status == 200) {
                res.data.outTradeNo
                if(this.isWx){
                    if(res.data && res.data.wxJsParams){
                        this.pullWchatPay(res.data.wxJsParams)
                    }else{
                        this.$emit("success")
                    }
                }else{
                    if(res.status == 200) {
                        if(isMobile()){
                            if(res.data && res.data.payUrl){
                                const div = document.createElement('form')
                                document.body.appendChild(div)
                                document.forms[document.forms.length - 1].setAttribute('action', res.data.payUrl) // 新开页面，需要在当前页唤起支付宝可去掉这一行
                                document.forms[document.forms.length - 1].setAttribute('method', "post")
                                document.forms[document.forms.length - 1].submit()
                                this.PayResultCheckVisible = true
                            }else{
                                this.$emit("success")
                            }

                        }else{
                            if(res.data && res.data.payUrl){
                                window.open(res.data.payUrl);
                                this.PayResultCheckVisible = true
                            }else{
                                this.$emit("success")
                            }


                        }
                    }
                }
            }
    },
    async initMounted(orgId) {

        this.orgId = orgId
        
        console.log("========initMounted=======>>>");
        if(this.isWx){
            
            // console.log("getQueryString('code')", getQueryString("code"))
            if(getQueryString("code")){
                console.log('》》》》》》》有code了===》》》》》》》》》')
                this.code = getQueryString("code")
                // console.log("code", this.code);

                var jsonStr = window.localStorage.getItem("wxPayProductInfo")
                if(jsonStr){
                    var productInfo = JSON.parse(jsonStr)
                    productInfo.code = this.code
                    window.localStorage.removeItem("wxPayProductInfo")
                    // console.log("productInfo", productInfo);
                    if(productInfo.refId){
                        this.refId = productInfo.refId
                    }

                    await this.createOrder({
                        payType:'wxpay', 
                        productId:productInfo.id, 
                        payNumber:productInfo.payNumber, 
                        code:this.code,
                        couponId: productInfo.couponId ? productInfo.couponId : null,
                        fdCouponTag: productInfo.fdCouponTag ? productInfo.fdCouponTag : null,
                        shareId: productInfo.shareId ? productInfo.shareId : null,
                    })
                }
            }else{
                //没有code，按正常流程走
                console.log('》》》》》没有code，什么也不干》》》》》》》')

                
            }

        }

    },
    //拉取微信支付的方法
    pullWchatPay(data){
        var that=this;
        WeixinJSBridge.invoke('getBrandWCPayRequest',
            {
                "appId":data.appId,
                "timeStamp":data.timeStamp,
                "nonceStr":data.nonceStr,
                "package":data.package,
                "signType":data.signType,
                "paySign":data.paySign
            },
            (res)=> {
                // console.log("支付返回", res)
                if(res.err_msg==='get_brand_wcpay_request:ok'){
                    localStorage.setItem('shareId', null)
                    this.$store.commit('SET_shareId', null)
                    that.$message.success('支付成功！')
                    this.$emit("success")
                }else{
                    that.$message.error("支付失败")
                }
                this.PayResultCheckVisible = true

            }
        )
    },

    setCoupon({couponId, fdCouponTag}){
        if(couponId){
            this.couponId = couponId
        }
        if(fdCouponTag){
            this.fdCouponTag = fdCouponTag
        }
    },
    setShare({shareId}){
        if(shareId){
            this.shareId = shareId
        }
    },

}
}
</script>

<style lang='scss' scoped>

@media screen and (max-width: 900px){

}

</style>