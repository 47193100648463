import { jugeIsIOS } from "@/utils";



export let touchClick = {
    bind: function (el, binding, vnode) {

        var startPoint

        function touchstart(e){
            
            startPoint= {
                x: e.changedTouches[0].pageX,
                y: e.changedTouches[0].pageY
            };


            // if(binding.value.close){
            //     binding.value.close()
            // }

        }

        function touchend(e){
            if(!startPoint){
                return
            }

            var now = {
                x: e.changedTouches[0].pageX,
                y: e.changedTouches[0].pageY
            };

            let dis = Math.sqrt(  (now.x - startPoint.x)*(now.x - startPoint.x) + (now.y - startPoint.y)*(now.y - startPoint.y)  )

            // alert("dis  = " + dis)

            if(dis < 24){
                if(binding.value.touchClick){
                    binding.value.touchClick(binding.value.data)
                }
            }
            startPoint = null

        }
        
        el.startHandle = touchstart
        el.endHandle = touchend

        if(jugeIsIOS()){
            el.addEventListener('touchstart', touchstart);
            el.addEventListener('touchend', touchend);
        }



    },

    unbind(el, binding) {    // 解除事件监听
        el.removeEventListener('touchstart', el.startHandle);
        el.removeEventListener('touchend', el.endHandle);
    }
}