import axios from 'axios'
import { Notification, Message, Loading } from 'element-ui'
import cache from './cache'
import { HTTP_URI } from '../api/api'
import store from '../store'
import router from '../router/index'
import { getQueryString, gotoHref, isMobile } from '@/utils'
import { getToken } from '@/service/login'
import { STORAGE_PRELOGIN_PAGE } from '@/service/constant'

//通知接口白名单（这个名单内的接口，出错不在这儿弹通知）
var notifyWhilt = [
  '/gpt/chat',
  '/v2/client/chatLog/autoUpdateTitle'
]

// loading开始 方法
function startLoading() {
  store.commit('SET_LOADING',true)
  // element-ui loading 服务调用方式
//   loading = Loading.service({
//     customClass: 'loading_icon',
//     fullscreen: true,
//     lock: true,
//     text: '加载中...',
//     spinner: 'el-icon-loading', // 自定义图标
//     background: 'rgba(255, 255, 255, 0.1)'
//   })
}
// loading结束 方法
function endLoading() {
  // loading.close()
  store.commit('SET_LOADING',false)
}

// 定义一个变量，有请求变量加一，收到响应变量减一
let loadingCount = 0
/**
 * 调用一次startLoadingAddCount() 方法 开启Loading 并 loadingCount + 1
 * 调用一次endLoadingSubCount() 方法 loadingCount - 1 直到为0 关闭loading
 */
export function startLoadingAddCount() {
  if (loadingCount === 0) {
    startLoading()
  }
  loadingCount++
}

export function endLoadingSubCount() {
  loadingCount--
  if(loadingCount < 0){
    loadingCount = 0
  }
  if (loadingCount === 0) {
    endLoading()
  }
}
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: HTTP_URI.BASEURL,
  // 超时
  timeout: 600000
})

// request拦截器
service.interceptors.request.use(
  config => {
    // console.log("config ========= ", config)
    if(config.loading){
      startLoadingAddCount()
    }
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    // 是否需要防止数据重复提交
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
    const token = getToken()
    if (token && !isToken) {
      config.headers['token'] = token // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    if(isMobile()){
        config.headers['DeviceType'] = 'Mobile'
    }
    if( config.headerData ){
        Object.assign(config.headers, config.headerData)
    }
    
    if (!isRepeatSubmit && config.method === 'post') {
      const datas = config.data
      const data = Object.keys(datas).map(item => {
        if( !config.skipNullDel ){//有skipNullDel就不要过滤空字符串和null
          if (datas[item] === '' || datas[item] == null){ 
              delete datas[item] 
          }
        }
        
      })
      const requestObj = {
        url: config.url,
        data: data,
        time: new Date().getTime()
      }
      const sessionObj = cache.session.getJSON('sessionObj')
      if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
        cache.session.setJSON('sessionObj', requestObj)
      }
    }
    return config
  },

  error => {
    endLoadingSubCount()
    Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  res => {
    endLoadingSubCount()
    // 未设置状态码则默认成功状态
    const code = res.data.status || 200
    // 获取错误信息
    const msg = res.data.message || res.data.msg

    var needNotify = true//是否需要消息通知
    for(var item of notifyWhilt){
    //   console.log("res.config.url", res.config.url)
      if(res.config.url.endsWith(item)){
        needNotify = false
        break
      }
    }

    if (code !== 200) {
      
      let error = res.data
      if(error.status == 10001){
        // console.log('10001')
          window.localStorage.removeItem('token')
          window.localStorage.removeItem('userInfo')
          window.localStorage.removeItem('userId')
          window.localStorage.removeItem('password')
          store.commit('SET_OPEN', false)
          store.commit('SET_TOTAL', 0)
          store.commit('SET_USERINFO', {})
          store.commit('SET_MESSAGES', [])
          store.commit('SET_AVATAR', '')
          store.commit('SET_SCOKET', {})
          store.commit('SET_NOTICE','')
          store.commit('SET_OPEN_MENU', false)
          store.commit('SET_TOKEN', null)
          store.commit('SET_HAVE_USER_INF', false)

          var murmur = window.localStorage.getItem('murmur')

          window.localStorage.clear()

          window.localStorage.setItem('murmur', murmur)

            setTimeout(()=>{

                // var userCode = getQueryString("userCode")
                // if(userCode){
                //     location.href = `${location.origin}?userCode=` + userCode

                // }else{
                //     location.href = location.origin
                // }

                localStorage.setItem(STORAGE_PRELOGIN_PAGE, location.href)
                router.push("/login")
        
            }, 500)

      }else if(error.status == 502){
        if(needNotify){
          Notification.error({
            title: "系统正在升级，请1分钟后再试"
          })
        }
        
      }else if(error.status == 101){
      }else if(error.status == 4){
        router.push("/noPermission")
      }else{
        if(needNotify){
          Notification.error({
            title: msg
          })
          return res.data
        }
        
      }
      return Promise.reject(res.data)
      // else if(error.status == 1){
      //   router.push('/white')
      // }
    }
    else {
        return res.data
    }
  },
  error => {
    endLoadingSubCount()

    console.log("error", error)
    
    var needNotify = true//是否需要消息通知
    // for(var item of notifyWhilt){
    //   if(res.config.url.endsWith(item)){
    //     needNotify = false
    //     break
    //   }
    // }

    let { message } = error
    if (message === 'Network Error') {
      message = '网络连接错误'
    } else if (message.includes('timeout')) {
      message = '系统接口请求超时'
    } else if (message.includes('Request failed with status code')) {
      message = '系统接口' + message.substr(message.length - 3) + '异常'
    }
    if(needNotify){
      // Message({
      //   message: message,
      //   type: 'error',
      //   duration: 5 * 1000
      // })
    }
    
    return Promise.reject(error)
  }
)
export default service
