export const defaultAppTag = "bXYMhP"//默认应用的tag值
export const STORAGE_PRELOGIN_PAGE  = "STORAGE_PRELOGIN_PAGE"
export const IS_ANONYMOUS_KEY = 'isAnonymous'
export const gzh_Qrcode_key = "gzh_qrcode"

/**
 * 图片分析
 */
export const MODEL_VALUE_IMG_ANALYSIS = 64


/**
 * 画图
 */
export const MODEL_VALUE_DRAW = 32

/**
 * 语音输入
 */
export const MODEL_VALUE_VOICE_INPUT = 16

/**
 * 文字转语音
 */
export const MODEL_VALUE_WORD__VOICE = 4

export function getModelUnitText(type){
    if(type == 1){
        return "次"
    }else if(type == 2){
        return " token"
    }else if(type == 3){
        return "字"
    }else if(type == 4){
        return "秒"
    }else if(type == 5){
        return "算力"
    }
}
export function getModelBaseNumber(type){
    if(type == 1){
        return 1
    }else if(type == 2){
        return 1000
    }else if(type == 3){
        return 100
    }else if(type == 4){
        return 60
    }else if(type == 5){
        return 1
    }
}