<template>
    <div class="myPageDialogWrap" v-clickOut="{close:inputOutTouch, excludeIds:['menuBtn'], dowm:true}">
        <div @click="btnClick">
            <slot>
                <i class="iconfont icon-liebiao menuBtn"></i>
            </slot>
        </div>
        <div class="content" v-show="dialogVisible" >
            <myPageContent @logout="logoutHandle" @invite="inviteHandle" @myOrg="myOrgHandle" @close="closeHandle" ref="myPageContentRef"></myPageContent>
            <i class="el-icon-close closeBtn" @click="closeBtnClick"></i>
        </div>
    </div>
</template>

<script>
import REQUEST from '@/api/http';
import { mapState } from 'vuex'
import myPageContent from './myPageContent.vue';

export default {
components: { myPageContent },
data() {
   return {
        dialogVisible:false,
        count:0
   }
},

computed:{
   ...mapState([ ]),

},
watch: {
    $route: {
        handler(to, from) {
            this.dialogVisible = false
        },
        deep: true,
    }
},
async mounted() {

},
methods: {
    btnClick(){
        this.dialogVisible = !this.dialogVisible
        if(this.dialogVisible){
            this.$refs.myPageContentRef.init()
        }
    },
    inputOutTouch(){
        if(this.dialogVisible == false){
            return
        }
        // this.dialogVisible = false
    },
    closeBtnClick(){
        this.$refs.myPageContentRef.close()
        this.$nextTick(() => {
            this.dialogVisible = false
        })
    },
    logoutHandle(){
        this.$emit("logout")
        this.dialogVisible = false
    },
    inviteHandle(){
        this.$emit("invite")
        this.dialogVisible = false
    },
    myOrgHandle(){
        this.$emit("myOrg")
        this.dialogVisible = false
    },
    closeHandle(){
        this.dialogVisible = false
    }
}
}
</script>

<style lang='scss' scoped>
.myPageDialogWrap{
    position: relative;
}
.menuBtn{
    color: #fff;
    cursor: pointer;
    position:relative;
}
.content{
    width: 400px;
    background: #fff;
    box-shadow: 0px 5px 14px 0px rgba(71, 71, 71, 0.192);
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    position: absolute;
    right: 0;
    top: 20px;
    z-index: 9999;
    padding-bottom: 20px;
}
.closeBtn{
    position: absolute;
    right: 10px;
    top: 10px;
    color: #555;
    // display: none;
    cursor: pointer;
}
.closeBtn:hover{
    position: absolute;
    right: 10px;
    top: 10px;
    color: #409EFF;
    // display: none;
    cursor: pointer;
}
@media screen and (max-width: 900px){
    .content{
        position: fixed;
        width: 100%;
        height: 100%;
        right: 0;
        top: 0;
        border-radius: 0;
    }
    .closeBtn{
        display: block;
    }
}

</style>