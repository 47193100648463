<template>
    <div>
        <div class="qrcodeFinishWrap">
            <!-- <el-button type="primary" v-if="!qrcodeFinishRegist && !qrcodeFinishBindMobil" @click="regist">注册</el-button>
            <el-button type="primary" v-if="!qrcodeFinishRegist && !qrcodeFinishBindMobil" @click="bindMobil">绑定手机号</el-button> -->

            <vconsoleSwitch class="vconsoleSwitch"></vconsoleSwitch> 

            <account_reg v-if="qrcodeFinishRegist" :openId="openId" :hideLoginBtn="true" :backState="backState" @chanePage="registFinish"></account_reg>
            <bind v-if="qrcodeFinishBindMobil" :openId="openId" @chanePage="bindFinish" :inviterId="inviterId"></bind>
        </div>
    </div>
  </template>
  
  <script>
    import {getQueryString, isTel} from '../utils/index'
    import account_reg from './account_reg.vue'
    import bind from './bind.vue'
    import vconsoleSwitch from '../components/vconsoleSwitch.vue'
    import { mapState } from 'vuex'
    import { gzh_Qrcode_key } from '@/service/constant'
    import { getToken } from '@/service/login'


  export default {
    components: { account_reg ,bind,vconsoleSwitch},
    props:["openId", "inviterId"],
    data() {
        return {
            qrcodeFinishRegist:false,//是否显示扫码后的注册界面
            qrcodeFinishBindMobil:false,//是否显示扫码后的绑定界面
            interValId:null,
            backState:false,
        }
    },
    mounted() {
        this.backState = false
        this.bindMobil()

        // this.interValId = setInterval(()=>{
        //     console.log("定时器");
        //     console.log("localStorage.getItem(gzh_Qrcode_key)", localStorage.getItem(gzh_Qrcode_key));

            
        //     console.log("getToken()", getToken());


        //     if(getToken() && !localStorage.getItem(gzh_Qrcode_key)){
        //         console.log("已经在别的地方登录好了");
        //         clearInterval(this.interValId)
        //         this.interValId = null
        //         localStorage.removeItem(gzh_Qrcode_key)
        //         this.bindFinish()
        //     }
        // }, 3300)

        this.$bus.$on('afterQrcodeClearInterval',this.afterQrcodeClearInterval)
    },
    beforeDestroy(){
        // console.log("afterQrcode  beforeDestroy");
        if(this.interValId){
            clearInterval(this.interValId)
            this.interValId = null
        }
    },
    methods: {
        init(){

        },
        canRegist(){
            this.qrcodeFinishRegist = true
            this.qrcodeFinishBindMobil = false
            this.backState = true
        },
        regist(){
            this.qrcodeFinishRegist = true
            this.qrcodeFinishBindMobil = false
        },

        bindMobil(){
            this.qrcodeFinishRegist = false
            this.qrcodeFinishBindMobil = true
        },
        registFinish(){
            if(this.interValId){
                clearInterval(this.interValId)
                this.interValId = null
            }
            
            this.$emit('chanePage')
        },
        bindFinish(){
            if(this.interValId){
                clearInterval(this.interValId)
                this.interValId = null
            }

            // console.log('-----  afterQrcode  chanePage-------');
            this.$emit('chanePage')
        },
        afterQrcodeClearInterval(){
            if(this.interValId){
                clearInterval(this.interValId)
                this.interValId = null
            }
        }
    }
  }
  </script>
  
  <style scoped lang="scss">
  

  
  </style>