<template>
    <el-dialog :visible.sync="visible" 
            :before-close="handleClose"  
            :width="width"
            :close-on-click-modal="false"
            center
            title=""
            append-to-body>
            
            <MenberVipInner ref="MenberVipInnerRef"></MenberVipInner>
    </el-dialog>

</template>
  
<script>
import { isMobile, isTel, routerPush } from '@/utils'

import { mapState } from 'vuex'
import store from '@/store'
import router from '@/router'
import Botton from "@/components/button/Button.vue"
import REQUEST from '@/api/http'
import { permissionDictionary } from '@/service/dictionary'
import UploadAvatar from '@/components/uploadImg/UploadAvatar.vue'
import ChangePwd from '@/layout/changePwd.vue'
import card from './card.vue'
import MenberVipInner from './MenberVipInner.vue'
 
export default {
    components: {Botton, UploadAvatar, ChangePwd, card, MenberVipInner},
    data() {
        return {
            
            
        }
    },
    props:{
        visible:Boolean
    },
    async mounted(){

    },
    watch: {
        visible(){
            if(this.visible){
                
            }
        }
    },
    computed:{
        ...mapState(['scoket', 'openMenu', 'total', 'userInfo', 'avatar', 'notice', 'gpt3Total', 'gpt4Total', 'loginVisible', 'haveUserInfo', , 'nickName', 'afterQrcodeShow', 'topInviteBtnShow']),
        width(){
            if(isMobile()){
                return "90%"
            }
            return "500px"
        }
    },
    methods:{
        init(){
            this.$refs.MenberVipInnerRef.start()
        },

        handleClose(){
            this.$emit('update:visible', false)
        }
        
    }
}
</script>
  
<style scoped lang="scss">

.infoWrap{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 28px 0;
    .infoTitle{
        color: #606266;
        padding-right: 16px;
        width: 109px;
        text-align: right;
        
    }
    .avatar{
        width: 60px;
        height: 60px;
        border-radius: 60px;
    }
    .infoRight{
        color: #666;
        padding-right: 5px;
    }
}


.footerWrap{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 40px;
}
.sureBtn{
    margin-left: 60px;
}
.t1{
    padding: 16px 0 8px;
    font-size: 16px;
    color: #363636;
}

.header{
    padding: 30px 30px 0;
    display: flex;
    flex-direction: row;
    position: relative;
    .topImg{
        width: 70px;
        height: 70px;
        border-radius: 10px;
        flex-shrink: 0;
    }
    .headerRight{
        flex-grow: 1;
        padding-left: 12px;
    }
    .headerTitle{
        font-size: 18px;
        color: #444;
    }
    .headerDes{
        font-size: 12px;
        color: #a3a3a3;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* 设置行数，这里示例设置为2行 */
        line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 22px;
        padding-top: 6px;
    }
}



@media screen and (max-width: 900px) {



}

</style>