import REQUEST from '@/api/http'
import Vue from 'vue'
import Vuex from 'vuex'

export default {
    state: ()=>{
        return {
            // chatMenuListA:[],//左侧列表的数据
            // chatMenuListB:[],//左侧列表的数据

            // curTalkDetailList:[]//当前显示的对话记录
            curAppIcon:"",//当前app的图标
            curAppId:1,
            curAppName:"",
            curApp:null,//当前进入的app

            talkList:[],//当前选择的对话里的聊天记录

            similarAnswers:[],//相似度比较高的答案

            chatDetailList:[],//当前选择的对话里的聊天记录

            selIndex:0,

            isAudioPlaying:false,//当前是否有音频正在播放

        }
    },
    mutations:{
        // SET_chatMenuListA: (state, data) => {
        //     state.chatMenuListA = data
        // },
        // SET_chatMenuListB: (state, data) => {
        //     state.chatMenuListB = data
        // },
        // SET_curTalkDetailList: (state, data) => {
        //     state.curTalkDetailList = data
        // }

        SET_curAppIcon: (state, data) => {
            state.curAppIcon = data
        },
        SET_curAppId: (state, data) => {
            state.curAppId = data
        },
        SET_curAppName: (state, data) => {
            state.curAppName = data
        },
        SET_curApp: (state, data) => {
            state.curApp = data
        },

        SET_talkList: (state, data) => {
            state.talkList = data
        },

        SET_similarAnswers: (state, data) => {
            state.similarAnswers = data
        },


        SET_chatDetailList: (state, data) => {
            state.chatDetailList = data
        },
        

        SET_selIndex: (state, data) => {
            state.selIndex = data
        },
        
        SET_isAudioPlaying: (state, data) => {
            state.isAudioPlaying = data
        },
        

        
    },
    actions:{
        loadChatDetailList: ({commit})=>{
            return new Promise(async (resolve, reject)=>{
                
                var res = await REQUEST.POST("HOME_V2", {"sendType": 1})


                // commit('SET_TOTAL', res.data.remainingTimes)
                // commit('SET_GPT3_TOTAL', res.data.remainingTimesGpt3)
                // commit('SET_GPT4_TOTAL', res.data.remainingTimesGpt4)
                // commit('SET_AVATAR', res.data.avatar)


            })
        }
    }
}