
<template>
  <el-dialog title="提示"
    :visible.sync="dialogVisibles"
    width="320px"
    append-to-body
    :before-close="handleClose">
    {{tip}}
    <span slot="footer"
      class="dialog-footer">
      <el-button
        @click="handleClose">取消</el-button>
      <el-button
        type="primary"
        @click="layOut">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { gotoHref } from '@/utils'
import { setToken } from '@/service/login'
import { STORAGE_PRELOGIN_PAGE } from '@/service/constant'
export default {
  data() {
    return {
      dialogVisibles: false,
      tip:''
    }
  },
  methods: {
    open(tip) {
        this.tip = tip?tip:'确认退出登录吗？'
        this.dialogVisibles = true
    },
    handleClose() {
        this.dialogVisibles = false
        // this.$router.push('/')
        this.$router.go(0)
    },
    layOut() {
        // removeHasShowVipPic()
        let isXmrc = JSON.parse(JSON.stringify(this.$store.state.isXmrc))
        this.$store.commit('SET_TOKEN', null)
        this.$store.commit('SET_orgId', '')
        this.$store.commit('SET_avatar', '')
        this.$store.commit('SET_nickName', '')
        this.$store.commit('SET_userId', '')
        setToken(null)
        this.$store.commit('SET_HAVE_USER_INF', false)
        window.localStorage.clear()
        localStorage.setItem(STORAGE_PRELOGIN_PAGE, location.href)
        this.$message.success('正在退出')
        this.$store.commit('SET_isXmrc', isXmrc)
        var orgTag = this.$store.state.orgTag
        if(orgTag){
            gotoHref('/ss/'+orgTag)
        }else{
            gotoHref('/login')
        }
      // window.localStorage.clear()
      // window.localStorage.setItem('loginNum', 2)
      // this.$store.commit('SET_OPEN', false)
      // this.$store.commit('SET_TOTAL', 0)
      // this.$store.commit('SET_USERINFO', {})
      // this.$store.commit('SET_AVATAR', '')
      // this.$store.commit('SET_NOTICE', '')
      // this.$store.commit('SET_MESSAGES', [])
      // this.$store.commit('SET_LS_TOTALS', 0)
      // this.$store.commit('SET_SCOKET', {})
      // this.$store.commit('SET_OPEN_MENU', false)
      // this.$message.success('正在退出')
      // window.location.href = '/login'
      // window.location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>