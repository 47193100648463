import AppParameSetDialog from "./AppParameSetDialog.vue";

export default function(Vue) {
  //继承基础信息
  let DialogConstructor = Vue.extend(AppParameSetDialog);

  var curShowINstance = null

  Vue.prototype.$appParameSetDialog = {

    show(options, callback){

        var contain
        if(options.containEl){
            contain = options.containEl
        }else{
            contain = document.body
        }
        //创建实例
        let Instance = new DialogConstructor();
        //手动挂载实例
        Instance.$mount();
        contain.appendChild(Instance.$el);

        Instance.init(options, (logId)=>{
            callback(logId)
            contain.removeChild(Instance.$el);
            curShowINstance = null
        })
        curShowINstance = Instance

    },
    remove(){
        if(curShowINstance){
            var parentNode = curShowINstance.$el.parentNode
            parentNode.removeChild(curShowINstance.$el);
            curShowINstance = null
        }
    }
  }


}
