export var modelTypeDictionary = [
    {
        type:1,
        text:"gpt3.5"
    },{
        type:8,
        text:"gpt4"
    }
]

/**
 * 嘉宾权限码
 */
export const PERMISSION_GUEST_CODE = 2048

/**
 * 管理员权限码
 */
export const PERMISSION_MANAGER_CODE = 1025


export var permissionDictionary = [
    {
        value:1,
        name:"应用管理与知识库管理"
    },{
        value:1024,
        name:"成员管理"
    },{
        value:PERMISSION_GUEST_CODE,
        name:"嘉宾"
    },{
        value:1048576,
        name:"对话"
    },{
        value:PERMISSION_MANAGER_CODE,
        name:"管理员"
    }
]