export var dbClick = {
    bind: function (el, binding, vnode) {

        var t = 0

        function clickHandler(e){
            var newTime = (new Date()).getTime()

            if(newTime - t < 500){
                if(binding.value.dbClick){
                    binding.value.dbClick()
                }
                t = 0
            }else{
                t = newTime

            }

        }
        

        el.__vueClickOutside__ = clickHandler;
        document.addEventListener('click', clickHandler);
        
    },

    unbind(el, binding) {    // 解除事件监听
        document.removeEventListener('click', el.__vueClickOutside__);
        delete el.__vueClickOutside__;
    }
}