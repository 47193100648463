<template>
  <div class="vconsoleSwitch" @click="btnClick">
  </div>
</template>

<script>
import { isMobile } from '@/utils'
export default {
  data() {
    return {
      count:0
    }
  },
  props:{
    
  },
  mounted() {
    
  },
  methods: {
    btnClick(){
      this.count ++
      if(this.count >= 10){
        if(isMobile()){
          document.getElementById("__vconsole").style.display = "block"
        }
      }
    }
    
  }
}
</script>

<style scoped>
.vconsoleSwitch{
  width: 80px;
  height: 80px;
  background: #cfcfcf;
  /* background: #f00; */
  z-index: 9999;
  opacity: 0;
}
</style>