import { render, staticRenderFns } from "./ChangeAvatarDialog.vue?vue&type=template&id=797e9246&scoped=true"
import script from "./ChangeAvatarDialog.vue?vue&type=script&lang=js"
export * from "./ChangeAvatarDialog.vue?vue&type=script&lang=js"
import style0 from "./ChangeAvatarDialog.vue?vue&type=style&index=0&id=797e9246&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "797e9246",
  null
  
)

export default component.exports