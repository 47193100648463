<!--
 * @Description:: 育小帮科技有限公司
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-02-14 11:29:12
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-22 11:12:25
 * @FilePath: /AIGo.work/src/components/modal/modal.vue
-->
<template>
  <!-- 组件开始 -->
  <el-dialog :title="title"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :fullscreen="fullscreen"
    append-to-body
    :width="width"
    :show-close="showClose"
    :before-close="handleClose">
    <slot></slot>
    <!-- 组件按钮 -->
    <span
      v-if="cancelText || okText"
      slot="footer"
      class="dialog-footer">
      <el-button
        v-if="cancelText"
        @click="handleClose">{{
        cancelText
      }}</el-button>
      <el-button v-if="okText"
        type="primary"
        :disabled="loading"
        @click="handleSubmit"><i
          v-if="loading"
          class="el-icon-loading"></i>{{
        okText
      }}</el-button>
    </span>
  </el-dialog>
  <!-- 组件结束 -->
</template>

<script>
export default {
  name: 'Yxb3NewModal',
  props: {
    showClose: {
      type: Boolean,
      default: true
    },
    // 头部
    title: {
      type: String,
      default: ''
    },
    // 取消按钮文字
    cancelText: {
      type: String,
      default: '取 消'
    },
    // 确认按钮文字
    okText: {
      type: String,
      default: '确 定'
    },
    // 宽度
    width: {
      type: String,
      default: '560px'
    },
    // 显示隐藏
    dialogVisible: {
      type: Boolean,
      default: false
    },
    // 按钮loading
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fullscreen: false
    }
  },
  watch: {
    // 监听按钮值得变化
    loading(newVal, oldPwd) {
      this.loading = newVal
    }
  },
  mounted() {
    this.fullscreen = JSON.parse(window.localStorage.getItem('phone'))
  },

  beforeDestry(){},

  methods: {
    // 关闭方法
    handleClose() {
      this.$emit('cancel')
    },
    // 确定方法
    handleSubmit() {
      this.$emit('ok')
    }
  }
}
</script>

<style lang="scss" scoped></style>
