<template>
    <div class="messageContainerOuter">
        <div class="messageContainer" :class="bgClass" @click="msgClick">
            <i class="el-icon-success successIcon" v-if="type == 1"></i>
            <i class="el-icon-warning warningIcon" v-if="type == 2"></i>
            <i class="el-icon-info errorIcon" v-if="type == 3"></i>

            <div :class="iconColor" class="text">{{ text }}</div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        
        type: 1,
        text: "",
        clickFun:null
      };
    },
    mounted() {},
    computed:{
        iconColor(){
            if(this.type == 1){
                return {successIcon:true}
            }else if(this.type == 2){
                return {warningIcon:true}
            }else if(this.type == 3){
                return {errorIcon:true}
            }
        },

        bgClass(){
            if(this.type == 1){
                return {successBgColor:true}
            }else if(this.type == 2){
                return {warningBgColor:true}
            }else if(this.type == 3){
                return {errorBgColor:true}
            }
        }
        
    },
    methods: {
        msgClick(){
            if(this.clickFun){
                this.clickFun()
            }
        }
    },
  };
  </script>
  
  <style scoped>
  .messageContainerOuter{
    width: 100vw;
    position: fixed;
    top: 30px;
    z-index: 2002;
    display: flex;
    flex-direction: row;
    justify-content: center;

  }
  .messageContainer{
    max-width:  400px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 24px;
    box-sizing: border-box;
    background: rgb(240, 249, 235);
    border:1px solid rgb(189, 255, 156);
    border-radius: 6px;
  }
  .text{
    padding-left: 5px;
    line-height: 20px;
    font-size: 12px;
  }
  .successIcon{
    color: rgb(140, 187, 118);
  }
  .warningIcon{
    color: rgb(184, 145, 86);
  }
  .errorIcon{
    color: rgb(255, 115, 115);
  }

  .successBgColor{
    background: rgb(240, 249, 235);
    border-color: rgb(140, 187, 118);
  }
  .warningBgColor{
    background: rgb(255, 235, 206);
    border-color: rgb(184, 145, 86);
  }
  .errorBgColor{
    background: rgb(255, 218, 218);
    border-color: rgb(255, 115, 115);
  }
  @media screen and (max-width: 900px){
    .messageContainer{
        max-width: 90vw;
    }
  }
  </style>
  