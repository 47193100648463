<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-03-20 21:02:46
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-29 11:22:53
 * @FilePath: /AIGo.work/src/components/notice/notice.vue
-->
<template>
  <div
    class="notice-card-wrapper box">
    <div class="header">
      <div class="bg_white"
        :class="{phone:phone}">
        <i
          class="el-icon-message-solid icon"></i>
      </div>
      <div class="title"
        v-if="notice">

        <!-- 系统公告 -->
        <div class="message">

          <div v-if="notice"
            class="inner-container">
            <span
              v-html="notice"></span>
          </div>
        </div>

      </div>
    </div>
    <!-- <el-button type="text"
      class="more_btn"
      @click="$emit('open')">查看更多</el-button> -->
  </div>
</template>

<script>
export default {
  props: ['notice'],
  name: 'SystemNotice',
  components: {},
  data() {
    return {
      phone: false
    }
  },
  computed: {},
  created() {
    this.phone = JSON.parse(window.localStorage.getItem('phone'))
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.notice-card-wrapper {
  height: 30px;
  // background-color: rgb(235, 250, 215);
  background: #f4f5f6;
  padding: 0px;
  position: relative;
  width: 100%;
  overflow: hidden;
  .bg_white {
    width: 270px;
    height: 30px;
    background: #f4f5f6;
    position: absolute;
    text-align: right;
    top: 0;
    left: 0;
    z-index: 99;
    color: #cccccc;
  }
  .bg_white.phone {
    width: 30px;
  }

  .header {
    height: 30px;
    margin-left: 240px;
    line-height: 30px;
    // background: #ffffff;
    .title {
      padding-left: 10px;
      i {
        color: #d9d9d9;
      }
    }
  }
  .more_btn {
    position: absolute;
    right: 10px;
    top: 0px;
    line-height: 8px;
    height: 30px;
    width: 100px;
    background: white;
    // background: rgb(235, 250, 215);

    z-index: 99;
  }
  .inner-container {
    line-height: 30px;
    margin-left: 100%; // 把文字弄出可见区域
    width: 1000%;
    height: 30px;
    overflow: hidden;
    animation: myMove 30s linear infinite; // 重点，定义动画
    animation-fill-mode: forwards;
  }
  /*文字无缝滚动*/
  @keyframes myMove {
    0% {
      transform: translateX(240px);
    }
    100% {
      transform: translateX(-2500px);
    }
  }
}
</style>


