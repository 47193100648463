/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-05-16 17:02:06
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-31 16:54:32
 * @FilePath: /AIGo.work/src/utils/index.js
 */
import store from '../store/index'
import router from '../router/index.js'
import SnowflakeId from "snowflake-id";
import { IS_ANONYMOUS_KEY } from '@/service/constant';
import { modelTypeDictionary } from '@/service/dictionary';
import BigNumber from 'bignumber.js';


export const clearStore = () => {
  setTimeout(()=>{
    window.localStorage.clear()
    window.localStorage.setItem('loginNum', 2)
    store.commit('SET_OPEN', false)
    store.commit('SET_TOTAL', 0)
    store.commit('SET_USERINFO', {})
    store.commit('SET_AVATAR', '')
    store.commit('SET_NOTICE', '')
    store.commit('SET_MESSAGES', [])
    store.commit('SET_LS_TOTALS', 0)
    store.commit('SET_SCOKET', {})
    store.commit('SET_OPEN_MENU', false)
    gotoHref("/login")

  },2000)
  
}

export function isMobile(){
  let flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  return flag;
}

export function strCharLen(str){
  let lenght = 0;

  var list = Array.from(str)
  for(var k = 0; k < list.length; k++){
      var ggg = list[k]
      // cc.log( list[k] )
      // cc.log(ggg.length)
      if(ggg.length == 2){//长度为2说明是表情
          lenght++;
      }else{
          if (list[k].charCodeAt(0) > 255) {
              lenght += 2;
          }else{
              lenght++;
          }
      }
  }

  return lenght;
}


export function saveLog(str){

  var logObj
  if(window.localStorage.getItem('log')){
    logObj = JSON.parse(window.localStorage.getItem('log'))
  }else{
    logObj = []
  }

  var date = new Date()

  var t = `${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`

  logObj.push({text:str, t})

  if(logObj.length > 200){
    logObj.shift()
  }

  window.localStorage.setItem('log', JSON.stringify(logObj))
}


export function getQueryString(name) {

  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  
  var r = window.location.search.substring(1).match(reg);
  
  if (r != null) {
  
  return decodeURIComponent(r[2]);
  
  }
  
  return null;
  
}

export function getQueryStrByUrl(url, paramName){
  const searchParams = new URLSearchParams(new URL(url).search);
  return searchParams.get(paramName) || null;
}

export function isWx(){
    if(!isMobile()){
        return false
    }
  var ua = navigator.userAgent.toLowerCase();
  var isWeixin = ua.indexOf('micromessenger') != -1;
  // console.log("是否微信", isWeixin)
  return isWeixin
}



//保留小数点后两位
export function dot2Num(str) {

  var num = new Number(str);

  return removeTrailingZeros(num.toFixed(2))
    
}



//保留小数点后n位
export function dotNum(str, n) {

    var num = new Number(str);
  
    return removeTrailingZeros(num.toFixed(n))
      
  }

//把对象的值对弄成query参数
export function convertJsonToQueryString(json) {
  var queryString = "";
  for (var key in json) {
    if (json.hasOwnProperty(key)) {
      var value = json[key];
      queryString += encodeURIComponent(key) + "=" + encodeURIComponent(value) + "&";
    }
  }
  // 去掉最后一个多余的"&"
  queryString = queryString.slice(0, -1);
  return queryString;
}

export function waitTimes(t){
  return new Promise((resolve, reject)=>{
    setTimeout(() => {

      resolve()
      
    }, t);
  })
}

export function isTel(str){
    var reg = /^1[3456789]\d{9}$/;
    // ^1  以1开头
    // [3456789] 第2位，使用原子表里的任意一个原子都可以
    // \d{9}$  第三位  朝后可以是任意数字  并且最后结尾必须是数字
    
    if(reg.test(str)){
      console.log('手机格式合法');
      return true;
    }else{
      // console.log('不合法');
      console.log('手机格式不正确');
      return false;
    }
}

export function date2XXXX_XX_XX(date){

  return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`
  
}

/**
 * Date转xxxx-xx-xx 10:20
 * @param {*} date 
 * @returns 
 */
export function formateDate(date){
    const yyyy = date.getFullYear()
    const m = date.getMonth() + 1
    const mm = m < 10 ? `0${m}` : `${m}`
    const d = date.getDate()
    const dd = d < 10 ? `0${d}` : `${d}`
    const h = date.getHours()
    const hh = h < 10 ? `0${h}` : `${h}`
    const ms = date.getMinutes()
    const mn = ms < 10 ? `0${ms}` : `${ms}`
    const s = date.getSeconds()
    const ss = s < 10 ? `0${s}` : `${s}`
    let times = `${yyyy}-${mm}-${dd} ${hh}:${mn}:${ss}`
    return times
}

export function jugeIsAndroid(){
  var u = navigator.userAgent
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
  return isAndroid
}

export function jugeIsIOS(){
  var u = navigator.userAgent
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
  return isiOS
}

export function routerPush(path){
    // router.push({
    //     path:path,
    //     query:router.currentRoute.query
    // })

    var userCode = getQueryString("userCode")
    if(userCode){
      
      router.push({
            path:path,
            query:router.query
        })
    }else{
      router.push(path)
    }
}

export function createOnlyId(){
  const snowflake = new SnowflakeId({
      mid: 42,
      offset: (2019 - 2018) * 31536000 * 1000
  });
  var snowId = snowflake.generate() + "" + window.localStorage.getItem('murmur')
  return snowId
}

/*
* type: 'start'移动到开头，'end'移到到结尾
*/
export function moveEnd(el,type) {
  el.focus(); 
  if (window.getSelection) {               //ie11 10 9 ff safari
    var range = window.getSelection();     //创建range
    range.selectAllChildren(el);           //选择el子项
    if(type=='start'){
      range.collapseToStart();             //光标移至开头
    }else{
      range.collapseToEnd();               //光标移至最后
    }
  }
  else if (document.selection) {                  //ie10 9 8 7 6 5
    var range = document.selection.createRange(); //创建选择对象
    range.moveToElementText(el);                  //range定位到ele
    if(type=='start'){
      range.collapse();                           //光标移至开头
    }else{
      range.collapse(false);                      //光标移至最后
    }
    range.select();
  }
}

/**
 * 获取一个元素距离父元素顶部的距离
 */
export function getElementTop(element){
    return element.offsetTop
    var parentElement = element.parentElement;
    var distanceToTop = element.getBoundingClientRect().top - parentElement.getBoundingClientRect().top;
    return distanceToTop
}

export function listAutoScroll(curElement, contain){

  var top = getElementTop(curElement)

  

  if(top - contain.scrollTop > contain.offsetHeight){//如果在容器底下外面
    contain.scrollTop =  top - contain.offsetHeight + curElement.offsetHeight

  }else if(top - contain.scrollTop < 0){//如果在容器上面的外部
    contain.scrollTop =  top
  }


}

export function keepLastIndex(obj){

    // console.log(obj)
    // console.log(window.getSelection)
    // console.log(document.selection)
    if (window.getSelection) { //ie11 10 9 ff safari
        obj.focus(); //解决ff不获取焦点无法定位问题
        var range = window.getSelection(); //创建range
        range.selectAllChildren(obj); //range 选择obj下所有子内容
        range.collapseToEnd(); //光标移至最后
    } else if (document.selection) { //ie10 9 8 7 6 5
        var range = document.selection.createRange(); //创建选择对象
        //var range = document.body.createTextRange();
        range.moveToElementText(obj); //range定位到obj
        range.collapse(false); //光标移至最后
        range.select();
    }
    
}

/**
 * 获取元素相对于body的坐标
 * @param {*} element 
 * @returns 
 */
export function getElementPosition(element) {
  const rect = element.getBoundingClientRect();
  return {
    top: rect.top + window.scrollY,
    left: rect.left + window.scrollX
  };
}

/**
 * 获取当前时间
 */
export function getTimes() {
  const date = new Date()
  const yyyy = date.getFullYear()
  const m = date.getMonth() + 1
  const mm = m < 10 ? `0${m}` : `${m}`
  const d = date.getDate()
  const dd = d < 10 ? `0${d}` : `${d}`
  const h = date.getHours()
  const hh = h < 10 ? `0${h}` : `${h}`
  const ms = date.getMinutes()
  const mn = ms < 10 ? `0${ms}` : `${ms}`
  const s = date.getSeconds()
  const ss = s < 10 ? `0${s}` : `${s}`
  return `${yyyy}-${mm}-${dd} ${hh}:${mn}:${ss}`
}

export function gotoHref(path){

    window.location.href = path
    
    // var arr = location.href.split("?")
    // if(arr.length == 1){
    //     window.location.href = path 
    // }else{
    //     window.location.href = path + "?" +  arr[1]
    // }

}

export function isAnonymous(){

    var val = localStorage.getItem(IS_ANONYMOUS_KEY)
    if(val && val == 1){
        return true
    }
    return false

}

export function cancalAnonymous(){
    localStorage.removeItem(IS_ANONYMOUS_KEY)
    store.commit('SET_TOKEN', null)
}

//如果路由有带other参数，解析other参数，获取json里的值
export function getOtherRouterParam(key){
    if(router.currentRoute.params.other){
        var jsonObj = JSON.parse(decodeURIComponent(router.currentRoute.params.other))
        return jsonObj[key]
    }else{
        return null
    }
}

export function getOtherOrgId(){
    var orgId = getOtherRouterParam('orgId')
    if(!orgId){
        if(store.state.curOpenAppOrgid && store.state.curOpenAppOrgid != '0'){
            orgId = store.state.curOpenAppOrgid
        }else if(store.state.orgId && store.state.orgId != '0'){
            orgId = store.state.orgId
        }
    }
    return orgId
}

export function createOtherParamStr(obj){
    return encodeURIComponent(JSON.stringify(obj))
}

export function processParagraphs(str) {
    // 使用正则表达式匹配首尾都是"#"号的段落
    const regex = /##*(.*?)##*/g;
    
    // 使用replace方法替换匹配到的段落
    const result = str.replace(regex, `<a href="" type="question">$1</a>`);
    return result; 
}

/* 
* url 目标url 
* arg 需要替换的参数名称 
* arg_val 替换后的参数的值 
* return url 参数替换后的url 
*/ 
export function changeURLArg(url,arg,arg_val){ 
    var pattern=arg+'=([^&]*)'; 
    var replaceText=arg+'='+arg_val; 
    if(url.match(pattern)){ 
        var tmp='/('+ arg+'=)([^&]*)/gi'; 
        tmp=url.replace(eval(tmp),replaceText); 
        return tmp; 
    }else{ 
        if(url.match('[\?]')){ 
            return url+'&'+replaceText; 
        }else{ 
            return url+'?'+replaceText; 
        } 
    } 
    return url+'\n'+arg+'\n'+arg_val; 
} 


export function isPureNumber(str) {
    return /^[0-9]*(\.[0-9]+)?$/.test(str);
}

/**
 * 输入一个数字，返回的数字最多带两个小数点
 * @param {*} num 
 * @returns 
 */
export function formatFloat(num) {
    // 将输入转换为浮点数
    var parsedNum = parseFloat(num);
  
    // 使用 toFixed() 函数保留两位小数
    var formattedNum = parsedNum.toFixed(2);
  
    // 返回结果
    return formattedNum;
}
/**
 * 从字符串里解析出audio的src
 * @param {*} str 
 * @returns 
 */
export function extractSrcFromAudioTag(str) {  
    // 创建一个新的 DOMParser 实例  
    const parser = new DOMParser();  
    
    // 使用 DOMParser 解析字符串为一个 XML 文档  
    const doc = parser.parseFromString(str, 'text/html');  
    
    // 使用 querySelector 查找 audio 标签  
    const audioElement = doc.querySelector('audio');  
    
    // 返回 audio 标签的 src 属性值  
    return audioElement ? audioElement.getAttribute('src') : null;  
  }

//写一个函数，输入一个字符串，判断该字符串是否以“画：”开头，冒号可以中文的也可以是英文的，画和冒号之间允许有空格，画前面也允许有空格
export function startsWithHua(str) {
    // var regExp = /^\s*画\s*[:：]/;
    // return regExp.test(str);
    var newStr=str.indexOf("画");
    if(newStr==0){
        return true
    }else{
        return false
    }
}

export function appModelTypes_to_modelTypes(obj){
    
    
    // var list = []
    // for(var i = 0; i < modelTypeDictionary.length; i++){
    //     var n = modelTypeDictionary[i].type & obj.data.appModelTypes
    //     if(n != 0){
    //         list.push({type:modelTypeDictionary[i].type})
    //     }
    // }
    // obj.data.modelTypes = {list:list}

}


export function processRichTextImg(html) {
    // 创建一个临时的 DOM 元素
    var tempElement = document.createElement('div');
    tempElement.innerHTML = html;
  
    // 获取所有的 img 标签
    var imgTags = tempElement.getElementsByTagName('img');
  
    // 遍历每个 img 标签
    for (var i = 0; i < imgTags.length; i++) {
      var imgTag = imgTags[i];
  
      // 给 img 标签添加 originUrl 属性，值为 src 的值
      imgTag.setAttribute('originUrl', imgTag.getAttribute('src') + "-aigo_shuiyin");
  
      // 修改 img 标签的 src 属性，在末尾添加 "-thumbnail"
      imgTag.setAttribute('src', imgTag.getAttribute('src') + '-thumbnail');
    }
  
    // 返回处理过的富文本
    return tempElement.innerHTML;
  }

  /**
   * 小数点后是否多于count位,是返回true，不是返回false，如果不是数字，也返回false
   * @param {*} num 
   * @param {*} count 
   * @returns 
   */
  export function hasDecimalPlaces(num, count) {  
    if (typeof num !== 'number') {  
        return false;  
    }  
    var arr = num.toString().split('.')
    if(arr.length < 2){
        return true
    }
    var str = num.toString().split('.')[1]; // 获取小数点后的部分  
    if (str && str.length <= count) { // 如果小数点后有小于等于两位数字，返回true  
        return true;  
    }  
    return false;   
}

/**
 * 输入一个日期，返回它距离今天的天数
 * @param {} dateString 
 * @returns 
 */
export function daysSince(dateString) {  
    // 将输入的日期字符串转换为Date对象
    let date = new Date(dateString?.replace(/-/g, '/'));  
  
    // 获取当前日期和时间  
    let now = new Date();  
  
    // 计算两个日期之间的差异（以毫秒为单位）  
    let diff = now - date;  
  
    // 将差异转换为天数  
    let days = Math.floor(diff / (1000 * 60 * 60 * 24));  
  
    return days;  
}

export function convert_fen_to_yuan(num){
    return BigNumber(num).div(100)
}


export function updateMetaData(description, keywords) {  
    // 获取当前页面唯一的meta元素  
    var metaTag = document.querySelector('meta[name="description"]');  
    
    // 如果meta元素不存在，则创建一个新的meta元素  
    if (!metaTag) {  
      metaTag = document.createElement('meta');  
      metaTag.name = 'description';  
      document.head.appendChild(metaTag);  
    }  
    
    // 修改description属性  
    metaTag.content = description;  
    
    // 获取当前页面唯一的meta元素  
    var keywordsMetaTag = document.querySelector('meta[name="keywords"]');  
    
    // 如果keywords元数据元素不存在，则创建一个新的meta元素  
    if (!keywordsMetaTag) {  
      keywordsMetaTag = document.createElement('meta');  
      keywordsMetaTag.name = 'keywords';  
      document.head.appendChild(keywordsMetaTag);  
    }  
    
    // 修改keywords属性  
    keywordsMetaTag.content = keywords;  
  }

  export function compareDates(day1, day2) {  
    // 确保输入是日期字符串  
    const date1 = new Date(day1);  
    const date2 = new Date(day2);  
    
    // 判断输入是否是有效的日期字符串  
    if (isNaN(date1.getTime()) || isNaN(date2.getTime())) {  
      throw new Error('Invalid date format. Expected: "YYYY-MM-DD HH:mm:ss"');  
    }  
    
    // 比较日期  
    if (date1 > date2) {  
      return 1; // day1比day2晚  
    } else if (date1 < date2) {  
      return -1; // day1比day2早  
    } else {  
      return 0; // 两个日期相同  
    }  
  }

/**
 * 判断一个元素是否在页面外面
 * @param {*} element 
 * @returns 
 */
  export function isElementOutOfViewport(element) {  
    const rect = element.getBoundingClientRect();  
    return rect.top > 0 && rect.bottom < window.innerHeight;  
  }  


  /**
   * 用于获取一个元素相对于其父元素的坐标
   * @param {*} element 
   * @returns 
   */
  export function getElementPositionRelativeToParent(element) {  
    var parentRect = element.parentNode.getBoundingClientRect();  
    var elementRect = element.getBoundingClientRect();  
  
    // 计算元素相对于父元素的顶部和左侧坐标  
    var top = elementRect.top - parentRect.top;  
    var left = elementRect.left - parentRect.left;  
  
    return {  
        top: top,  
        left: left  
    };  
} 


export function isInIframe() {
    try {
        // 如果页面在iframe中，window与parent会不相等
        return window.self !== window.top;
    } catch (e) {
        // 如果跨域了，直接访问window.top可能会抛出异常
        // 此时可以认为页面在iframe中
        return true;
    }
}


export function highlightContent(str, bgColor) {  
    // 使用正则表达式匹配#{}包裹的内容  
    const regex = /#\{([\s\S]*?)\}/g;  
    let result = str;  
    
    // 使用while循环和exec方法查找所有匹配项  
    let match;  
    while ((match = regex.exec(str)) !== null) {
        // 匹配到的内容不包含#{}，所以直接使用match[1]  
        const content = match[1];
            
        // 创建一个新的span元素  
        const span = document.createElement('span');  
            
        // 设置span的背景色  
        span.style.backgroundColor = bgColor;  
            
        // 将匹配到的内容设置为span的文本  
        span.textContent = content;  
            
        // 用span替换原始字符串中的匹配项  
        result = result.replace(match[0], span.outerHTML);
    }

    return result;  
  }  

  /**
   * 价格分转元
   */
export function priceFen2Yuan(n){
    return BigNumber(n).div(100)
}

export function wrapMatchesWithSpan(str1, arr1, color) {  
    // 创建一个正则表达式，匹配arr1中的每个字符串  
    const regex = new RegExp(arr1.join('|'), 'gi');  
    
    // 使用replace()方法将匹配的字符串包装在span标签中  
    return str1.replace(regex, function(match) {  
      return `<span style="background-color:${color}">${match}</span>`;  
    });  
}

export function addSpaceAfterUrls(text) {  
  // 正则表达式匹配网址  
  // 这个表达式相对简单，只匹配 http://, https://, ftp:// 开头的网址  
  // 以及不以字母数字开头和结尾，且包含至少一个点号（.）的字符串  
  const urlRegex = /(\b(https?:\/\/|ftp:\/\/)?[^\s()<>]+[\w\.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+)/gi;  
  
  // 使用正则表达式的exec方法在字符串中查找所有匹配项  
  let match;  
  let result = text;  
  while ((match = urlRegex.exec(text)) !== null) {  
    // 在找到的网址后面添加一个空格  
    result = result.replace(match[0], match[0] + ' ');  
  }  
  
  return result;  
  }  


export function removeTrailingZeros(number) {  
    return parseFloat(number).toString();
}  


export function formatDate(dateString) {  
    const date = new Date(dateString);  
    const year = date.getFullYear();  
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以加1  
    const day = String(date.getDate()).padStart(2, '0'); // 使用padStart确保是两位数  
    const hours = String(date.getHours()).padStart(2, '0');  
    const minutes = String(date.getMinutes()).padStart(2, '0');  
    const seconds = String(date.getSeconds()).padStart(2, '0');  
  
    // 注意：这里我们假设输入的时间已经是本地时间（GMT+0800），所以不需要进行时区转换。  
    // 如果输入时间不是本地时间，则需要先将其转换为本地时间。  
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;  
} 

export function deepClone(obj){
    return JSON.parse(JSON.stringify(obj))
}


export function checkInputLength (e, maxSize){
    let b = 0; // 输入的字符数
    for (let i = 0, length = e.length; i < length; i++) {
      let c = e.charAt(i);
      if (/^[\u0000-\u00ff]$/.test(c)) {
        b++;
      } else {
        b += 2;
      }
      if (b > maxSize) { // 字符长度限制
        var resultStr = e.substr(0, i);
        if (/^[\u0000-\u00ff]$/.test(c)) {
          b--;
        } else {
          b -= 2;
        }
        return resultStr
      }
    }
    return  e
}

// str1是个url，a是query参数名，删除url里名为a的参数
export function  processUrl(str1, a) {  
    // 判断str1中是否包含?来初步判断是否有query参数  
    const hasQueryParams = str1.includes('?');  
  
    if (!hasQueryParams) {  
        // 如果没有query参数，直接返回str1  
        return str1;  
    }  
  
    // 如果有query参数，则将其分割成数组  
    const queryParams = str1.split('?')[1].split('&');  
  
    // 创建一个Map来存储query参数  
    const paramMap = new Map();  
    queryParams.forEach(param => {  
        const [key, value] = param.split('=');  
        paramMap.set(key, value);  
    });  
  
    // 检查Map中是否有名为a的参数  
    if (paramMap.has(a)) {  
        // 如果有，从Map中删除它  
        paramMap.delete(a);  
  
        // 构造新的查询字符串  
        const newQueryParams = Array.from(paramMap.entries()).map(([key, value]) => `${key}=${value}`).join('&');  
  
        if(newQueryParams.length == 0){
            return `${str1.split('?')[0]}`
        }
        // 构造并返回新的URL  
        return `${str1.split('?')[0]}?${newQueryParams}`;  
    } else {  
        // 如果没有名为a的参数，直接返回原始str1  
        return str1;  
    }  
}  