import { date2XXXX_XX_XX } from "@/utils"

//今天vip弹窗是否弹过了
export function hasShowVipPic(){
    var shopwVipPicObj = window.localStorage.getItem("shopwVipPicObj")
    if(!shopwVipPicObj){
        return false
    }

    var todayStr = date2XXXX_XX_XX(new  Date())

    if(shopwVipPicObj == todayStr){
        return true
    }
    return false

}

export function setTodayHasShowVipPic(){

    var todayStr = date2XXXX_XX_XX(new  Date())

    window.localStorage.setItem("shopwVipPicObj", todayStr)

}


export function removeHasShowVipPic(){
    window.localStorage.removeItem("shopwVipPicObj")

}

