<template>
    <el-dialog :visible.sync="visible" 
        :before-close="handleClose"  
        :width="width"
        :close-on-click-modal="false"
        center
        title="充值"
        append-to-body>

        <div class="myMoney">我的余额：{{ myMoney }}</div>

        <div class="wrap">

            <div class="formItem">
                <div class="left">充值金额：</div>
                <div class="right">
                    <payRadio :list="pList" :selIndex.sync="selIndex" :type="1"></payRadio>
                </div>
            </div>
            <br>
            <div class="formItem">
                <div class="left">付款平台：</div>
                <div class="right">
                    <div class="payPlantform">
                        <div class="plantform plantformSel">
                            <div class="plantformItem" v-if="mobile && wx">
                                <img src="@/assets/pay/wxpay.png">
                                <div>微信支付</div>
                            </div>
                            <div class="plantformItem" v-if="mobile && !wx">
                                <img src="@/assets/pay/zfb.png">
                                <div>支付宝</div>
                            </div>
                            <div class="plantformItem" v-if="!mobile">
                                <img src="@/assets/pay/zfb.png">
                                <div>支付宝</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            

            <div class="footer">
                <el-button class="payBtn" type="primary" @click="payClick">支付</el-button>
            </div>

            
        </div>


    </el-dialog>
  </template>
  
  <script>
  import { mapState } from 'vuex'
  import 'highlight.js/styles/devibeans.css'
import { isMobile, isWx,getQueryString, getOtherOrgId, routerPush } from '@/utils'
  import payRadio from "../../components/payRadio/payRadio.vue"
import { getToken } from '@/service/login'
import BigNumber from 'bignumber.js'
  export default {
    data() {
      return {
        dialogVisible:false,
        isFinish:false,
        pList:[],
        selIndex:0,
        ruleForm:{
            title:"",
            memo:''
        },
        rules:{
            title:[
                { required: true, message: '名称不能为空', trigger: 'blur' }
            ]
        },
        code:null,
        myMoney:""
      }
    },

    props:{
        visible:Boolean
    },
    
    components:{payRadio},

    watch: {
        visible(){
            if(this.visible){

                this.loadProduct()


            }
        }
    },
    computed:{
        ...mapState([ 'haveUserInfo', 'token']),

        width(){
            if(isMobile()){
                return "90%"
            }
            return "500px"
        },

        mobile(){
            return isMobile()
        },
        wx(){
            return isWx()
        }

        

    },
  
    methods: {
        async loadProduct(){
            var orgId = getOtherOrgId()
            var res = await this.$https('queryGoods', {pageNumber: 1, pageSize:20, goodsType:0, status:1})

            if(res.data){
                res.data.records.forEach((val)=>{
                    val.name = val.goodsChargingWallet.name
                    val.giftAmount = val.goodsChargingWallet.giftAmount
                })
                this.pList = res.data.records
            }else{
                this.pList = []
            }
            
            this.getUserInfo()


        },
        handleClose(){
            this.$emit('update:visible', false)
            this.$emit('close')
        },

        payClick(){
            var payType
            if(this.wx){
                payType = 'wxpay'
                //跳转微信授权页
                //跳授权页

                //现把要买的产品的信息保存在本地，授权完跳转过来后再取出来，并移除
                var jsonStr = JSON.stringify(this.pList[this.selIndex])
                window.localStorage.setItem("wxPayProductInfo", jsonStr)

                var url_no_query = location.href.split("?")[0]//去掉后面的query参数，因为如果有code，微信还是会再加一个code，而不是覆盖
                var redirect_uri = encodeURIComponent(url_no_query)
                location.href=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxcf27a05a564d021d&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`



            }else{
                payType = 'alipay'
                this.createOrder(payType, this.pList[this.selIndex].id)

            }
        },

        async createOrder(payType, productId, code){
            var res = await this.$https('createOrder', {payNumber:1, payType:payType, productId:productId, code:code})
            if (res.status == 200) {
                res.data.outTradeNo
                if(this.wx){
                    this.pullWchatPay(res.data.wxJsParams)
                }else{
                    if(res.status == 200) {
                        if(isMobile()){
                            const div = document.createElement('form')
                            document.body.appendChild(div)
                            document.forms[document.forms.length - 1].setAttribute('action', res.data.payUrl) // 新开页面，需要在当前页唤起支付宝可去掉这一行
                            document.forms[document.forms.length - 1].setAttribute('method', "post")
                            document.forms[document.forms.length - 1].submit()
                        }else{
                            window.open(res.data.payUrl);
                        }
                    }
                }
            }
        },


        async zhifubaoPay({money, name, orderId}){
            var param = {
              money: money,
              name: name,
              outTradeNo: orderId,
              isPc:!isMobile()
            }
            try{
                var alipayRes = await this.$https('ALIPAY_GOTOPAY', param)
                if(alipayRes.status == 200) {
                    if(isMobile()){

                        const div = document.createElement('form')
                        document.body.appendChild(div)
                        // document.forms[document.forms.length - 1].setAttribute('target', '_blank') // 新开页面，需要在当前页唤起支付宝可去掉这一行
                        document.forms[document.forms.length - 1].setAttribute('action', alipayRes.data) // 新开页面，需要在当前页唤起支付宝可去掉这一行
                        document.forms[document.forms.length - 1].setAttribute('method', "post")

                        document.forms[document.forms.length - 1].submit()

                    }else{
                        window.open(alipayRes.data);
                    }
                }

            }catch(e){

            }
        },

        async getWxPayUrl({money, name, orderId}){

            var param = {
                body:"充值", 
                outTradeNo:orderId, 
                payAmount:money,
                subject:name,
                code:this.code,
                goodsLabel:name
            }

            var res = await this.$https('GET_WX_PAY_JS_PARAM', param)
            console.log("获取支付参数返回", res)
            this.pullWchatPay(res.data)


        },

        //拉取微信支付的方法
        pullWchatPay(data){
            var that=this;
            WeixinJSBridge.invoke('getBrandWCPayRequest',
                {
                    "appId":data.appId,
                    "timeStamp":data.timeStamp,
                    "nonceStr":data.nonceStr,
                    "package":data.package,
                    "signType":data.signType,
                    "paySign":data.paySign
                },
                function (res) {
                    console.log("支付返回", res)
                    if(res.err_msg==='get_brand_wcpay_request:ok'){
                        
                        that.$message.success('支付成功！')
                        that.getHome()
                        this.$emit('update:visible', false)
                        this.$emit('close')

                        

                    }else{
                        that.$message.error("支付失败")

                    }
                }
            )
        },
        async getUserInfo(){
            try{
                var res = await this.$https('GET_USERINFO', {})
                // console.log("res", res)
                if(res.data){
                    this.myMoney = BigNumber(res.data.walletBalance).div(1000) + " (星舰币)"
                }
            }catch(e){
                console.error(e);
            }
        },
        async initMounted() {


            if(this.wx){
                // console.log("getQueryString('code')", getQueryString("code"))
                if(getQueryString("code")){
                    console.log('》》》》》》》有code了》》》》》》》》》')
                    this.code = getQueryString("code")

                    var jsonStr = window.localStorage.getItem("wxPayProductInfo")
                    if(jsonStr){
                        var productInfo = JSON.parse(jsonStr)
                        productInfo.code = this.code
                        window.localStorage.removeItem("wxPayProductInfo")

                        await this.createOrder('wxpay', productInfo.id, this.code)

                        // var res = await this.$https('GET_WX_PAY_JS_PARAM', param)
                        // console.log("获取支付参数返回", res)
                        
                        // this.pullWchatPay(res.data)
                    }

                    this.phone = JSON.parse(window.localStorage.getItem('phone'))
                    if (getToken()) {
                        if(!this.haveUserInfo){
                            await this.$store.dispatch('loadHomeData')
                        }
                        this.loadProduct()

                    }
                }else{
                    //没有code，按正常流程走
                    console.log('》》》》》没有code，按正常流程走》》》》》》》')

                    this.phone = JSON.parse(window.localStorage.getItem('phone'))
                    if (getToken()) {
                        if(!this.haveUserInfo){
                            await this.$store.dispatch('loadHomeData')
                        }
                        this.loadProduct()

                    }
                }

            }else{
                this.loadProduct()

            }




        },

        
        
    }
  }
  </script>
<style lang="scss" scoped>

.payPlantform{
    display: flex;
    flex-direction: row;
}
.plantform{
    width: 100px;
    height: 40px;
    border:1px solid #eee;
    color:#777777;
    font-size: 16px;
    margin: 12px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}
.plantformSel{
    border:1px solid #7e2dff;
}

.plantformItem{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    img{
        width: 20px;
        height: 20px;
    }
    div{
        padding-left: 6px;
    }
}

.formItem{
    display: flex;
    flex-direction: row;
    .left{
        width: 90px;
        text-align: right;
        font-size: 14px;
        color: #555;
        flex-shrink: 0;
        padding-top: 18px;
        
    }
    .right{
        flex-grow: 1;
    }
}

.footer{
    padding-top: 18px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.myMoney{
    text-align: center;
    font-size: 16px;
    color: #555;
    padding: 0 0 25px;
}


@media screen and (max-width: 900px) {
    .formItem{
        flex-direction: column;
        .left{
            padding-bottom: 11px;
            text-align: left;
            padding-left: 13px;
        }
    }
}
</style>