export var scrollBottom = {

    bind: function(el, binding) { 

        let callback = binding.value; 

        function scrollHandle(){


            var distance = parseInt(el.getAttribute('scroll-distance'));
            if(!distance){
                distance = 0
            }

            if (el.scrollHeight - el.scrollTop <= (el.clientHeight + distance)) { 
                callback(); 
            } 
        }

        el.addEventListener('scroll', scrollHandle); 
        el.__vueScrollHandle__ = scrollHandle;


    },

    unbind(el, binding) {    // 解除事件监听
        el.removeEventListener('scroll', el.__vueScrollHandle__);
        delete el.__vueScrollHandle__;
    }
}