<template>
    
    <div class="promotionWrap" v-if="visible">
        <div class="bg" @click="handleClose"></div>
        <div class="imgWrap" ref="wrapRef">
            <img class="posterImg" ref="imgRef" @load="imageLoaded" src="https://static.aigo.work/img/app-icon/aigoPoster/v2_rxgbr2.jpg?v=1"  >
            <a :href="link" class="link" target="_blank" @click="imgClick"></a>
            <div class="closeBtn" @click="handleClose">
                <i class="el-icon-close"></i>
            </div>
        </div>

    </div>
  </template>
  
  <script>
  import { mapState } from 'vuex'
  import 'highlight.js/styles/devibeans.css'
  import { HTTP_URI } from '@/api/api'
  import {isMobile, dot2Num, getQueryString} from "../../utils/index"
  import {generateImageWithQRCode, base64ToDownloadUrl} from "../../utils/picQRcode.js"
  import Loading4 from "../../components/directiveLoading/Loading4.vue"
  import store from '../../store/index.js'
  import REQUEST from '@/api/http'

  export default {
    data() {
      return {
        link:""
      }
    },
    props:{
        visible:Boolean,
    },
    
    components:{Loading4},
    mounted() {

        window.addEventListener("resize", ()=>{
            this.setSize()
        });
        window.addEventListener("DOMContentLoaded", ()=>{
            this.setSize() 
        });
        this.setSize()

        
        var userCode = getQueryString("userCode")
        if(userCode){

            this.link = "/product?userCode=" + userCode

        }else{

            this.link = "/product"

        }

      
    },
    watch: {
        async visible(){
            if(this.visible){
                this.setSize()

            }
        }
    },
    computed:{

        width(){
            if(isMobile()){
                return "90%"
            }
            return "700px"
        },
        posterImgStyle(){
            var height = (window.innerHeight - 230) * 0.8
            var width = height*0.7

            height = height + "px"
            width = width + "px"

            return {width, height}
        }

        

    },
  
    methods: {


        setSize(){
            // if(document.getElementsByClassName("posterImg").length > 0){
            //     document.getElementsByClassName("posterImg")[0].style.height = (window.innerHeight - 160) + "px"
            // }
        },
        handleClose(){
            this.$emit('update:visible', false)

        },
        imageLoaded(){
            setTimeout(() => {

                var naturalWidth = this.$refs.imgRef.naturalWidth
                var naturalHeight = this.$refs.imgRef.naturalHeight

                console.log("naturalWidth", naturalWidth)
                console.log("naturalHeight", naturalHeight)


                var w =  document.getElementsByClassName("imgWrap")[0].offsetWidth
                var h =  document.getElementsByClassName("imgWrap")[0].offsetHeight
                console.log("posterImg.width", w)
                console.log("posterImg.height", h)


                var realW, realH;
                if(naturalWidth/naturalHeight > w/h){
                    realW = w
                    realH = w/naturalWidth * naturalHeight
                }else{
                    realH = h
                    realW = h/naturalHeight * naturalWidth
                }

                this.$refs.wrapRef.style.width = realW + "px"
                this.$refs.wrapRef.style.height = realH + "px"

            }, 0);
        },
        imgClick(){
            this.handleClose()
        }
    }
  }
  </script>
  <style lang="scss" scoped>


    .promotionWrap{
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        .bg{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: #00000083;
        }
        .imgWrap{
            position: absolute;
            width: 70%;
            height: 70%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            
        }
        .posterImg{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        .closeBtn{
            width: 30px;
            height: 30px;
            position: absolute;
            right: 12px;
            top: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .link{
        display: block;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        position: absolute;
        left: 0;
        top: 0;
    }
    
  @media screen and (max-width: 900px) {
    
    
  }
  </style>