<template>

    <div class="payRadio">

        <div v-for="(item, index) in list" :key="index" class="item" :class="{itemSel:index == selIndex, item2:type==1}" @click="btnClick(index)">
            <div class="name">{{ item.name }}</div>
            <div class="gift" v-if="item.giftAmount && item.giftAmount > 0">赠{{ item.giftAmount/100 }}元</div>
        </div>

    </div>

</template>
  
  <script>
  import { HTTP_URI } from "@/api/api";
import REQUEST from "@/api/http";
import store from "@/store";
import axios from "axios";



  export default {
    name: "UploadHeadImg",
    components: {},
    props:["list", "selIndex", "type"],
    data() {
      return {

      };
    },

    watch: {
        
    },

    computed:{
        
    },
  
    mounted() {

    },
  
    methods: {
        btnClick(i){
            this.$emit("update:selIndex", i)
            this.$emit("selItem")
        }

    },
  };
  </script>
  
  <style lang="scss" scoped>

  .payRadio{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .item{
    width: 102px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    margin: 6px;
    cursor: pointer;
    .name{
        text-align: center;
        font-size: 13px;
        color: #2e2e2e;
    }
    .gift{
        color: #adadad;
        padding-top: 8px;
        font-size: 12px;
    }
  }
  .item2{
    height: 60px;
    .name{
        font-size: 15px;
    }
  }

  .itemSel{
    border: 1px solid #7a6bff;
    .name{
        color: #7a6bff;
    }
    .gift{
        color: #7a6bff;
    }
  }
@media screen and (max-width: 900px){
    .item{
        width: 31vw;
        height: 16vw;
    }
}

  </style>
  
  