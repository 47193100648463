export var scrollToUp = {
    bind: function (el, binding, vnode) {


        function scrollHandler(e){
            if (el.scrollTop === 0) {

                // var distance = el.getAttribute("distance")
                // console.log('el distance', distance);

                binding.value(); // 执行传入的回调函数
                
            }
        }
        

        el.__vueScrollHandler__ = scrollHandler;
        el.addEventListener('scroll', scrollHandler);
        
    },

    unbind(el, binding) {    // 解除事件监听
        el.removeEventListener('scroll', el.__vueScrollHandler__);
        delete el.__vueScrollHandler__;
    }
}