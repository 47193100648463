export var clickOut2 = {
    bind: function (el, binding, vnode) {


        function clickHandler(e){
            if (el.contains(e.target)) {     // 这里判断点击的元素是否是本身，是本身，则返回
                return false
            }

            
            // if(!binding.value.enable){
            //     return
            // }

            
            
            if(binding.value.excludeIds){
                //如果点到的是excludeIds里包含的元素
                if(binding.value.excludeIds.includes( e.target.id )){
                    return false
                }
            }


            if(binding.value.close){
                binding.value.close()
            }

        }
        
        setTimeout(()=>{
            el.__vueClickOutside__ = clickHandler;
            document.addEventListener('click', clickHandler);
        },100)
       
        
    },

    unbind(el, binding) {    // 解除事件监听
        document.removeEventListener('click', el.__vueClickOutside__);
        delete el.__vueClickOutside__;
    }
}