<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-04-16 18:55:34
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-23 15:15:56
 * @FilePath: /AIGo.work/src/layout/reg.vue
-->
<template>
  <div>
    <AccountReg v-if="regType == 1" @forgetPwd="$emit('forgetPwd')" @chanePage="$emit('chanePage',true)" @success="success"></AccountReg>
    <Email v-if="regType == 2" @chanePage="$emit('chanePage',true)"></Email>
    <GzhVue @chanePage="$emit('chanePage',true)" v-if="regType == 3"></GzhVue>
  </div>
</template>

<script>
import AccountReg from './account_reg.vue'
import Email from './email_reg.vue'
import GzhVue from './gzh.vue'
export default {
  components: { AccountReg, Email, GzhVue },
  props: ['regType'],
  methods:{
    success(){
      this.$emit("success")
    }
  }
}
</script>

<style>
</style>