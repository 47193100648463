<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-05-27 17:25:29
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-31 17:23:54
 * @FilePath: /AIGo.work/src/components/directiveLoading/loading.vue
-->
<template>
  <div class="loadings">
    <div
      class="first-loading-wrp">
      <div
        class="loading-wrp">
        <span
          class="dot dot-spin">
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </span>
      </div>
      <h1
        style="color:rebeccapurple;margin-top: -80px;">
        AIGo.work</h1>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      spinnerStyle: {}
    }
  }
}
</script>
<style>
.loadings {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  background: rgba(255, 255, 255, 0);
}
.first-loading-wrp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  min-height: 90vh;
}

.first-loading-wrp > h1 {
  font-size: 30px;
  font-weight: bolder;
}

.first-loading-wrp .loading-wrp {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 98px;
}

.dot {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  width: 64px;
  height: 64px;
  font-size: 64px;
  transform: rotate(45deg);
  animation: antRotate 1.2s infinite linear;
}

.dot i {
  position: absolute;
  display: block;
  width: 28px;
  height: 28px;
  background-color: rebeccapurple;
  border-radius: 100%;
  opacity: 0.3;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  animation: antSpinMove 1s infinite linear alternate;
}

.dot i:nth-child(1) {
  top: 0;
  left: 0;
}

.dot i:nth-child(2) {
  top: 0;
  right: 0;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.dot i:nth-child(3) {
  right: 0;
  bottom: 0;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.dot i:nth-child(4) {
  bottom: 0;
  left: 0;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

@keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }
}

@-webkit-keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }
}

@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}

@-webkit-keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
</style>