import REQUEST from '@/api/http'
import { formateDate } from '@/utils'
import Vue from 'vue'
import Vuex from 'vuex'

export default {
    state: ()=>{
        return {

            articleList:[]

        }
    },
    mutations:{

        SET_articleList: (state, data) => {
            state.articleList = data
        }
        

        
    },
    actions:{


    }
}