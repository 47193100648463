<template>

    <div class="bg" :class="{dialogWrapOpacity:isOpacity}" v-if="showStatus">
        <div class="alphaBg"></div>
        <div class="appPreSet shadow"  >

            <div class="appPreSetWrap">
                <div class="appName">
                    <img :src="icon">
                    <div>{{name}}</div>
                </div>
                <div class="summary">
                    {{ summary }}
                </div>
                <el-form :model="ruleForm" ref="ruleForm" label-width="auto" class="demo-ruleForm">
                    <div v-for="(item, index) of ruleForm.userValueList" :key="index">

                        <el-form-item class="formItem" v-if="item.type == 1" :label="item.name" :prop="'userValueList.' + index + '.value'" :rules="[{required: true, message: '不能为空', trigger: 'blur'}, { min: 1, max: item.maxLength, message: `不能超过${item.maxLength}个字符`, trigger: 'blur' }]">
                            <el-input  class="input" v-model="item.value" :placeholder="item.tip"></el-input>
                        </el-form-item>

                        <el-form-item class="formItem" v-else :label="item.name" :prop="'userValueList.' + index + '.value'" :rules="[{required: true, message: '不能为空', trigger: 'blur'}]">
                            <el-select v-model="item.value" :placeholder="item.tip">
                                <el-option v-for="item2 in item.options" :key="item2" :label="item2" :value="item2"></el-option>
                            </el-select>
                        </el-form-item>
                        
                    </div>
                </el-form>
                <div class="footer">
                    <div class="returnBtn" v-if="!store||!store.state.tool.isPreView" @click="backBtnClick">返回</div>

                    <div class="startBtn" @click="starClick('ruleForm')">确定</div>

                </div>
            </div>

            <!-- <i class="el-icon-close closeBtn" v-if="!store.state.tool.isPreView" @click="backBtnClick"></i> -->

        </div>
    </div>

</template>
  
<script>

import REQUEST from '@/api/http'
import { isMobile } from '@/utils'
import { mapState } from 'vuex'
import { routerPush } from '@/utils'

export default {
    components: {},
    props:["selIndex", "visible"],
    data() {
        return {
            input:"",
            phone: false,
            appId:1,
            tag:0,
            ruleForm:{
                userValueList:[]
            },
            isEdit:false,//是否是编辑
            rules:{
                
            },
            command:"",
            firstPrompt:"",
            // appVersionId:"",
            name:"",
            icon:'',
            summary:'',
            //3.5 sendType = 1
            //4.0 sendType = 8
            modelType:1,
            logId:"",
            finishCallback:null,
            isOpacity:true,
            store:null,
            showStatus: true
        }
    },
    async mounted(){
        this.showStatus = true
        this.phone = JSON.parse(window.localStorage.getItem('phone'))
    },
    computed:{

        width(){
            if(isMobile()){
                return "100%"
            }
            return "100%"
        },
        // ...mapState({
        //     curUseTool: state => state.tool.curUseTool,//当前选择的工具
        //     isPreView: state => state.tool.isPreView,
        //     isOwnerView: state => state.tool.isOwnerView,
        //     normalUseView: state => state.tool.normalUseView,
        //     appVersionId: state => state.tool.appVersionId,
        //     appDraftId: state => state.tool.appDraftId,
        // }),

    },
    methods:{

        /**
         * 
         * @param {*} type 1本人使用，2预览， 3正常使用
         * @param {*} modelType 
         */
        async init({type, modelType, appId, firstPrompt, command, name, params, logId, icon, summary, store}, finishCallback){
            this.modelType = modelType

            this.finishCallback = finishCallback
            this.icon = icon
            this.summary = summary

            this.appId = appId

            this.firstPrompt = firstPrompt
            this.command = command
            this.name = name
            this.logId = logId

            this.store = store

            this.store.commit('SET_showisAppParameSetDialog', true)

            this.ruleForm.userValueList = []
            if(params && params.length > 0){
                params = JSON.parse( JSON.stringify(params) )
                params.forEach((item)=>{
                    item.value = ''
                })
                this.ruleForm.userValueList = params
                this.isOpacity = false


            }else{
                // this.starClick('ruleForm')
                this.isOpacity = true
                this.send()
                
            }
            
        },
        async starClick(formName){
            
            var fist = this.firstPrompt
            // console.log("第一句话", fist)

            this.$refs[formName].validate(async (valid)=>{
                if (valid) {
                    this.send()
                }
            })
            
        },
        async send(){
            var valueParams = {}
            this.ruleForm.userValueList.forEach((val)=>{
                // fist = fist.replaceAll(`{{${val.name}}}`, val.value)
                valueParams["{{" + val.name + "}}"] = val.value
            })

            // console.log("用户输入的valueParams", valueParams)
            var param = {params:valueParams}

            if(this.store.state.tool.isOwnerView){//创建者自己访问

                // param.appVersionId = this.$route.params.appVersionId
                param.appVersionId = this.store.state.tool.appVersionId
            }else if(this.store.state.tool.isPreView){//预览

                if(this.store.state.tool.isFirstPreview){
                    param.appId = this.appId
                }else{
                    param.appDraftId = this.store.state.tool.appDraftId
                }
                
            }else if(this.store.state.tool.normalUseView){//正式访问
                param.appId = this.appId
            }

            param.modelType = this.modelType
            if(this.logId){
                param.logId = this.logId
            }
            var res = await this.$https('saveFirstSentenceV2', param)
            // console.log("保存第一句话和指令返回", res)
            
            // this.$emit("finish", res.data)
            this.isOpacity = true

            this.store.commit('SET_showisAppParameSetDialog', false)

            this.finishCallback(res.data, param.params)
        },

        handleClose(){

        },

        toolsClose(){
            this.store.commit('SET_showisAppParameSetDialog', false)
            this.finishCallback(false)
            this.$emit('update:visible', false)
        },
        backBtnClick(){
            var orgTag = this.store.state.orgTag
            if(orgTag){
                this.showStatus = false
                routerPush('/starship/'+orgTag)
            }else{
                this.toolsClose()
            }
        },
    }
}
</script>
  
<style scoped lang="scss">

.bg{
    width: 100%;
    height: 100%;
    position:absolute;
    left: 0;
    top: 0;
    z-index: 1000;
}
.alphaBg{
    width: 100%;
    height: 100%;
    position:absolute;
    left: 0;
    top: 0;
    background: #00000093;
}

.formItem::v-deep .el-input__inner{
    line-height: 40px !important;
    background: rgba(60, 82, 105, 0.13);
    border-color: rgb(60, 82, 105);
    color: #fff;
    box-shadow: rgba(146, 143, 143, 0.4) 0px 2px 15px 0px;
    border-radius: 10px;
}

.formItem::v-deep .el-input__inner::placeholder{
    color: #5a5a5a;
}

.formItem::v-deep .el-form-item__label{
    color: #fff;
}


.appPreSet{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 650px;
    min-height: 300px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgb(39, 47, 55);
    z-index: 100;
    border-radius: 12px;
    // box-shadow: rgba(146, 143, 143, 0.4) 0px 2px 15px 0px;
    // border: 1px solid rgb(60, 82, 105);
    padding: 30px 0;
}
.appPreSetWrap{
    width: 400px;
}

.appName{
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    // padding-bottom: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    img{
        width: 30px;
        height: 30px;
        border-radius: 6px;
        margin-right: 10px;
    }

}
.closeBtn{
    position: absolute;
    top: 15px;
    right: 15px;
    color: #7d7d7d;
    cursor: pointer;
}

.dialogWrapOpacity{
    opacity: 0;
}

.summary{
    font-size: 13px;
    line-height: 18px;
    color: rgba(159,159,159,1);
    padding: 28px 0;
}

.footer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 15px;
    padding-top: 25px;
}

.startBtn{
    width: 100px;
    height: 40px;
    line-height: 40px;
    margin-left: 40px;
    cursor: pointer;
    text-align: center;
    color: #fff;
    font-size: 13px;
    border-radius: 6px;
    background: linear-gradient(90deg, rgb(123, 53, 251), rgb(58, 46, 238));
}
.returnBtn{
    width: 100px;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    text-align: center;
    color: rgb(60, 82, 105);
    font-size: 13px;
    border-radius: 6px;
    border: 1px solid rgb(60, 82, 105);
}


@media screen and (max-width: 900px) {
    .appPreSet{
        width: 90%;
    }
    .appPreSetWrap{
        width: 85%;
    }
}

</style>