<template>


    <img :src="this.base64">

    
  </template>
  
  <script>
  import { mapState } from 'vuex'
  import 'highlight.js/styles/devibeans.css'
  import { HTTP_URI } from '@/api/api'
  import {isMobile, dot2Num} from "../../utils/index"
  import Loading4 from "../../components/directiveLoading/Loading4.vue"
  import store from '../../store/index.js'
    import REQUEST from '@/api/http'
  export default {
    data() {
      return {
        base64:""
      }
    },
    props:{
        
    },
    mounted(){
        this.base64 = this.$store.state.curPosterImgBase64
    }
    
    
  }
  </script>
  <style lang="scss" scoped>

  
    img{
        width: 100%;
        height: auto;
    }

  </style>