<template>
    <div class="card">
        <div class="cardTop">
            <div class="goodsName">{{ curGoods.goodsOrgMemberVip.name }}</div>
            <div class="time" v-if="isCure">{{ this.curGoods.expireTime }} 到期</div>
            <div class="goodsMemo" v-if="curGoods.memo">
                <span>会员权益: </span>
                {{ curGoods.memo }}
            </div>
        </div>
        <div class="countOut" v-if="curGoods.goodsOrgMemberVip">
            <div class="countWrap"  v-for="(item, index) in curGoods.goodsOrgMemberVip.countList" :key="index">
                <div class="countLeft">{{ item.modelName }}</div>
                <div class="countRight">
                    <span>{{ item.number }}</span> 
                    <span v-for="(itemUnit,indexUnit) in unitType" :key="indexUnit">
                        <span v-if="item.unitType==itemUnit.type">{{ itemUnit.name }}</span>
                    </span>    
                </div>
            </div>
        </div>

        <el-button v-if="renewBtnVisible&&curGoods.goodsOrgMemberVip.type!=1" class="renewBtn" type="primary" round @click="renewClick"> {{ curGoods.goodsOrgMemberVip.realPrice | priceFilter }}元 续费</el-button>
        <el-button v-if="upgradBtnVisible" class="upgradBtn" type="primary" round @click="upgradClick">{{ curGoods.buyType==1?curGoods.goodsOrgMemberVip.realPrice:curGoods.upgradePrice | priceFilter }}元 升级套餐</el-button>
    </div>
</template>

<script>
import REQUEST from '@/api/http';
import { mapState } from 'vuex'
import { daysSince } from '@/utils';
import BigNumber from 'bignumber.js';

export default {
components: {  },
data() {
   return {
        unitType:[
            {type:1,name:'次'},
            {type:2,name:' TOKEN'},
            {type:3,name:'字'},
            {type:4,name:'秒'}
        ]
   }
},
props:["curGoods", "isCure"],//isCure是否是当前套餐
filters:{
    priceFilter(num){
        return BigNumber(num).div(100)
    },
},
computed:{
   ...mapState([ ]),
   renewBtnVisible(){
        if(this.isCure){
            var days = daysSince(this.curGoods.expireTime)
            if(days > -90){
                return true
            }
            return false
        }else{
            return false
        }
   },
    upgradBtnVisible(){
            if(this.isCure){
                return false
            }else{
                return true
            }
    },
    async getChaJia(){
        
    }

},
async mounted() {

},
methods: {

    // showUpgradBtn(){
    //     this.upgradBtnVisible = true

    // },
    // showRenewBtn(){
    //     this.renewBtnVisible = true
    // }
    renewClick(){
        this.$emit("renewClick")
    },
    upgradClick(){
        this.$emit("upgradClick")
    }

}
}
</script>

<style lang='scss' scoped>


.card{
    position: relative;
    width: 100%;
    line-height: 20px;
    border-radius: 10px 10px 10px 10px;
    background: #fff;
    border: 1px solid #e0e0e0;
    margin: 0 auto 10px;
    padding-bottom: 20px;
}
.cardTop{
    background-color: rgba(241,238,255,1);
    padding: 22px 26px;
    .goodsName{
        font-size: 16px;
        color: #363636;
        text-align: center;
        padding-bottom: 10px;
    }
    .goodsMemo{
        font-size: 12px;
        color: #797979;
    }
    .time{
        text-align: center;
        color: #797979;
        font-size: 12px;
    }
}
.countOut{
    padding: 15px 0 20px;
}
.countWrap{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
    color: #777;
    padding: 0 26px 12px;
    .countLeft{

    }
    .countRight{
        color: #987DFF;
    }
}

.renewBtn{
    display: block;
    margin: 0 auto;
    padding: 10px 40px;
}
.upgradBtn{
    display: block;
    margin: 0 auto;
    padding: 10px 40px;
}

@media screen and (max-width: 900px){

}

</style>