import MyMessage from "./MyMessage.vue";

export default function(Vue) {
  //继承基础信息
  let MessageConstructor = Vue.extend(MyMessage);


  Vue.prototype.$message = {
    success:function(text){
        this.create({type:1, text:text})
    },
    warning:function(text){
        this.create({type:2, text:text})
    },
    error:function(text){
        this.create({type:3, text:text})
    },
    create({ type, text }){
        //创建实例
        let Instance = new MessageConstructor();
        //手动挂载实例
        Instance.$mount();
        document.body.appendChild(Instance.$el);


        //设置类型
        Instance.type = type;
        //设置信息
        Instance.text = text;

        //关闭
        var timeId = setTimeout(() => {
            //console.log("关闭了");
            document.body.removeChild(Instance.$el);
        }, 1500);

        Instance.clickFun = ()=>{
            document.body.removeChild(Instance.$el);
            clearTimeout(timeId)
        }
    }
  }


}
