<template>
  <div>
    <el-form :model="regform"
      :rules="regrules"
      ref="regform"
      label-width="0px"
      class="demo-ruleForm">
      <el-form-item prop="mobile">
        <el-input
          prefix-icon="el-icon-mobile-phone"
          placeholder="请输入手机号"
          v-model="regform.mobile">
        </el-input>
      </el-form-item>

      <el-form-item class="mobilCodeItem" prop="vcode">
        <el-input
          class="ipt"
          prefix-icon="el-icon-notebook-2"
          placeholder="请输入短信验证码"
          v-model="regform.vcode">
        </el-input>
        <div v-if="codeTime <= 0" type="primary" class="getMobilCodeBtn" @click="mobilCodeBtnClick">短信验证</div>
        <div v-else type="primary" class="getMobilCodeBtn getMobilCodeBtnGray">{{ codeTime }}</div>

      </el-form-item>

      <el-form-item style="margin-bottom: 0;">
        <el-button type="primary" @click="reglogin" style="width:100%">绑定手机号</el-button>
        <el-button type="text" @click="$emit('chanePage')" style="width:350px;margin-top:10px" v-if="hideLoginBtn">已有账号，立即登录</el-button>
      </el-form-item>
    </el-form>
    <SlideVerify
      @success="success"
      v-if="showSlide">
    </SlideVerify>
  </div>
</template>

<script>
import SlideVerify from '@/components/SlideVerify/index.vue'
import {getQueryString, isTel} from '../utils/index'
import { gzh_Qrcode_key } from '@/service/constant'
import { setToken } from '@/service/login'

export default {
  components: { SlideVerify },
  props:["openId", "hideLoginBtn", "inviterId"],
  data() {

    var checkMobil = (rule, value, callback)=>{
      if (!value) {
        return callback(new Error('手机号不能为空'));
      }

      var bool = isTel(value)
      if(bool){
        callback()
      }else{
        callback(new Error('手机号格式不正确'))
      }
    }




    return {
      regform: {
        mobile: '',
        vcode:"",
      },
      message: '',
      disabled: false,
      timer: null,
      showYzMode: false,
      showSlide: false,
      codeText: '获取验证码',
      regrules: {
        mobile: [
          // { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator:checkMobil , trigger: 'blur' }
        ],
        vcode:[{ required: true, message: '请输入短信验证码', trigger: 'blur' }],
      },
      codeTime:false,//倒计时时间
      codeTimeTotal:60,//两次获取验证码时间间隔必须大于这个数
      intervalId:null
    }
  },
  mounted() {
  },
  beforeDestroy(){
    clearInterval(this.intervalId)
  },
  methods: {
    
    reglogin() {
      this.$refs.regform.validate(valid => {
        if (valid) {
          if (this.showYzMode == true) {
            this.showSlide = true
          } else {

            if(this.inviterId){
              this.regform.inviterId = this.inviterId
            }

            if(this.openId){
              this.regform.fromUserName = this.openId
            }
            
            this.$https('GET_BIND_PHONE_NUMBER', this.regform).then(res => {
              if (res.status == 200) {
                this.$message.success('绑定成功！')

                localStorage.removeItem(gzh_Qrcode_key)
                
                this.$store.commit('SET_isXmrc', res.data.isXmrc)
                
                this.$store.commit('SET_TOKEN', res.data.token)
                setToken(res.data.token)
                this.$store.commit('SET_HAVE_USER_INF', false)
                this.$store.dispatch('loadHomeData')

                res.data.vipPackage.expiryTime = res.data.expiryTime;
                if(res.data.vipPackage) {
                  
                }

                this.$bus.$emit('afterQrcodeClearInterval')
                localStorage.removeItem(gzh_Qrcode_key)
                
                // console.log('------ chanePage 111111 -----');

                this.$emit('chanePage')
              }
            })
          }
        }
      })
    },
    success(data) {
      // this.showSlide = false
      // this.$https('ACCOUNT', this.regform).then(res => {
      //   console.log(res)
      //   if (res.status == 200) {
      //     this.$message.success('绑定成功！')
      //     this.$store.commit('SET_USERINFO', res.data)
      //     this.$store.commit('SET_TOKEN', res.data.token)

      //     window.localStorage.setItem('token', res.data.token)
      //     window.localStorage.setItem('userId', res.data.userId)
      //     res.data.vipPackage.expiryTime = res.data.expiryTime;
      //     if(res.data.vipPackage) {
      //       window.localStorage.setItem('vipInfo', JSON.stringify(res.data.vipPackage))
      //     }
      //     this.$emit('chanePage')
      //   }
      // })
    },
    async mobilCodeBtnClick(){
      if(this.codeTime > 0){
        return
      }

      this.$refs.regform.validateField("mobile", async valid => {
        if (!valid) {
          // 发送验证码

          var res = await this.$https('GET_SEND_VCODE', {mobile: this.regform.mobile, type: 2})
          console.log("发送验证码返回", res)

          this.codeTime = this.codeTimeTotal
          this.intervalId = setInterval(() => {
            this.codeTime -= 1
            if(this.codeTime <= 0){
              clearInterval(this.intervalId)
            }
          }, 1000);

        }
      })
    }
  }
}
</script>

<style scoped lang="scss">


.mobilCodeItem ::v-deep .el-form-item__content{
  display: flex;
  flex-direction: row;
}
.getMobilCodeBtn{
  margin-left: 5px;
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #45c084;
  color: #fff;
  font-size: 13px;
  border-radius: 4px;
  cursor: pointer;
}
.getMobilCodeBtnGray{
  background: #eeeeee;
  color: #707070;
  cursor: default;
}

.ipt {
  ::deep .el-input__inner {
    -webkit-text-security:disc!important;
  } 
}

</style>