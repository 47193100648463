<template>
    <div class="Button">
        <slot>
            Button
        </slot>
        
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        
      }
    }
  }
  </script>
  <style>
    .Button{ 
        width: 70px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: linear-gradient(90deg, rgb(60, 47, 240), rgb(117, 52, 249));
        color: #fff;
        border-radius: 6px;
        cursor: pointer;
    }
  </style>