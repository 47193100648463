<template>
    <el-dialog :visible.sync="visible" 
        :before-close="handleClose"  
        :width="width"
        :close-on-click-modal="false"
        center
        title="更换头像"
        append-to-body>


        <div class="wrap">
            <img class="img" :src="avatar" >

            <el-button type="primary" @click="changeBtnClick" >更换头像</el-button>
        </div>
        
        <UploadAvatar v-model="uploadImgVisible" @finish="uploadImgFinish" ></UploadAvatar>

    </el-dialog>
  </template>
  
  <script>
  import { mapState } from 'vuex'
  import 'highlight.js/styles/devibeans.css'
  import { HTTP_URI } from '@/api/api'
  import { isMobile } from '@/utils'
  import UploadAvatar from "../components/uploadImg/UploadAvatar.vue"
  
  export default {
    data() {
      return {
        uploadImgVisible:false
      }
    },

    props:{
        visible:Boolean
    },
    
    components:{UploadAvatar},
    mounted() {

      this.avataImg = this.avatarUrl

    },
    watch: {
        visible(){
            if(this.visible){

                

            }
        }
    },
    computed:{

        ...mapState(['avatar']),

        width(){
            if(isMobile()){
                return "90%"
            }
            return "400px"
        }
        

    },
  
    methods: {
        async uploadImgFinish(url){
            await this.$store.dispatch('loadHomeData')
        },

        changeBtnClick(){
            this.uploadImgVisible = true
        },

        handleClose(){
            this.$emit('update:visible', false)
        }
        
    }
  }
  </script>
<style lang="scss" scoped>

.wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}
.img{
    width: 200px;
    height: 200px;
    object-fit: cover;
    margin-bottom: 20px;
}


</style>