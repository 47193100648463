/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: 小白
 * @Date: 2022-03-02 13:14:18
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-03-22 13:39:07
 * @FilePath: /AIGo.work/src/api/http.js
 */
import request from './request'
import { HTTP_URI } from './api'
import {convertJsonToQueryString, getOtherOrgId, routerPush} from '../utils/index.js'

export const REQUEST = {
  POST: function (businessType, datas, loading=true, skipNullDel=false) {
    if (!HTTP_URI[businessType]) return

    var orgId = getOtherOrgId()

    var httpUrl = HTTP_URI[businessType]

    if(orgId){
        httpUrl = httpUrl + "?orgId=" + orgId
    }

    return request({
      url: httpUrl,
      method: 'post',
      data: datas,
      loading,
      skipNullDel
    })
  },
  POST_ORG: function (businessType, datas, orgId, loading=true, skipNullDel=false) {
    if (!HTTP_URI[businessType]) return

    var httpUrl = HTTP_URI[businessType]

    if(orgId){
        httpUrl = httpUrl + "?orgId=" + orgId
    }

    return request({
      url: httpUrl,
      method: 'post',
      data: datas,
      loading,
      skipNullDel
    })
  },
  GET: function (businessType, params) {

    var orgId = getOtherOrgId()
    if(orgId){
        params.orgId = orgId
    }

    return request({
      url: HTTP_URI[businessType],
      method: 'get',
      params: params
    })
  },
  GET_QUERY: function (businessType, params) {

    return request({
      url: HTTP_URI[businessType],
      method: 'get',
      params: params
    })
  },
  POST_NOLOADING: function (businessType, datas, loading=false, skipNullDel=false) {
    if (!HTTP_URI[businessType]) return

    var orgId = getOtherOrgId()

    var httpUrl = HTTP_URI[businessType]

    if(orgId){
        httpUrl = httpUrl + "?orgId=" + orgId
    }

    return request({
      url: httpUrl,
      method: 'post',
      data: datas,
      loading,
      skipNullDel
    })
  },
  POST_NOLOADING_ORG: function (businessType, datas, orgId, loading=false, skipNullDel=false) {
    if (!HTTP_URI[businessType]) return

    var httpUrl = HTTP_URI[businessType]

    if(orgId){
        httpUrl = httpUrl + "?orgId=" + orgId
    }

    return request({
      url: httpUrl,
      method: 'post',
      data: datas,
      loading,
      skipNullDel
    })
  },
  //post,但是地址后带query参数
  POST_QUERY: function (businessType, datas, loading=true) {
    if (!HTTP_URI[businessType]) return
    return request({
      url: HTTP_URI[businessType] + "?" + convertJsonToQueryString(datas),
      method: 'post',
      data: {},
      loading
    })
  },
  //post,但是地址后带query参数
  POST_QUERY_ORG: function (businessType, datas, loading=true) {
    if (!HTTP_URI[businessType]) return

    var orgId = getOtherOrgId()

    var httpUrl = HTTP_URI[businessType]
    if(orgId){
        httpUrl = httpUrl + "?orgId=" + orgId
    }
    return request({
      url: httpUrl + "&" + convertJsonToQueryString(datas),
      method: 'post',
      data: {},
      loading
    })
  },

  POST_URL: function (url, datas, loading=true) {
    return request({
      url: url,
      method: 'post',
      data: datas,
      loading
    })
  },
  
  POST_URL_QUERY: function (businessType, datas, loading=true, query=null) {
    if (!HTTP_URI[businessType]) return

    var url = HTTP_URI[businessType]
    if(query){
        url += ("?" + convertJsonToQueryString(query))
    }
    return request({
      url: url,
      method: 'post',
      data: datas,
      loading
    })
  },

  POST2: function ({businessType, datas, loading=true, query}) {
    if (!HTTP_URI[businessType]) return

    var url = HTTP_URI[businessType]
    if(query){
        url = url + "?" + convertJsonToQueryString(query)
    }
    return request({
      url: url,
      method: 'post',
      data: datas,
      loading
    })
  },

  POST2_NOLOADING: function ({businessType, datas, loading=false, query}) {
    if (!HTTP_URI[businessType]) return

    var url = HTTP_URI[businessType]
    if(query){
        url = url + "?" + convertJsonToQueryString(query)
    }
    return request({
      url: url,
      method: 'post',
      data: datas,
      loading
    })
  },
  
  //get， 但是参数是接在地址后面的路径
  GET_PATH: function (businessType, path) {
    return request({
      url: HTTP_URI[businessType] + path,
      method: 'get'
    })
  },

  POST_HEADER: function (businessType, datas, headerData, loading=true) {
    if (!HTTP_URI[businessType]) return

    var orgId = getOtherOrgId()
    
    var httpUrl = HTTP_URI[businessType]

    if(orgId){
        httpUrl = httpUrl + "?orgId=" + orgId
    }

    return request({
        url: httpUrl,
        method: 'post',
        data: datas,
        loading,
        headerData:headerData
    })
  },

}
export default REQUEST
