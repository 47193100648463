import store from "@/store"

export var permision = {
    bind: function (el, binding, vnode) {


        // var res = store.getters.havePermission(binding.value)

        var bool = true
        binding.value.forEach((val)=>{
            // store.state.orgPermission
            var res = store.getters.havePermission(val)
            if(!res){
                bool = false
            }
        })

        

        if(bool){
            el.style.display = "block"
        }else{
            el.style.display = "none"
        }
        
        
    },

    unbind(el, binding) {    // 解除事件监听
        
    }
}