export function getRightTopModelList(appObject){

    if(!appObject){
        return []
    }
    if(!appObject.hasOwnProperty('availableModels')){
        return []
    }

    var arr = []
    appObject.availableModels.forEach((val)=>{
        if(val.modelValue == 128 || val.modelGroup==1){
            arr.push(val)
        }
    })
    return arr


}