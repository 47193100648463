import REQUEST from '@/api/http'
import { permissionDictionary } from '@/service/dictionary'
import Vue from 'vue'
import Vuex from 'vuex'
import BigNumber from "bignumber.js";

export default {
    state: ()=>{
        return {

            myPermissionList:[],
            haveLoadPermission:false
            
        }
    },
    mutations:{
        SET_myPermissionList: (state, data) => {
            state.myPermissionList = data
        },

        SET_haveLoadPermission: (state, data) => {
            state.haveLoadPermission = data
        },

        
        
    },
    getters:{

        havePermission:(state)=>{
            return (id)=>{
                for(var i = 0; i < state.myPermissionList.length; i++){
                    if( state.myPermissionList[i].value == id ){
                        return true
                    }
                }
                return false
            }

        }

    },
    actions:{
        loadMyPermission: ({commit}, {orgId})=>{
            return new Promise(async (resolve, reject)=>{

                var res = await REQUEST.GET('getAvailablePermission', {orgId : orgId})
                if(res.data){
                    var list = []

                    permissionDictionary.forEach((val)=>{

                        // var num1 = new BigNumber(val.value)
                        // var num2 = new BigNumber(res.data)

                        const r = val.value & parseInt(res.data)
                        
                        // console.log(`${val.value} & ${parseInt(res.data)}`);

                        // console.log("位与的结果", r);
                        if(r != 0){
                            list.push(val)
                        }

                    })

                    commit('SET_myPermissionList', list)
                    commit('SET_haveLoadPermission', true)
                    resolve()
                }


            })
        }
    }
}