<template>

    <el-dialog :visible.sync="visible" 
            :before-close="handleClose"  
            :width="width"
            :close-on-click-modal="false"
            center
            title="个人信息"
            append-to-body>

            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">

                <div class="infoWrap">
                    <div class="infoTitle">头像：</div>
                    <img class="avatar" :src="avatar?avatar:require('@/assets/WechatIMG3.jpeg')"  @click="avatarClick">
                    <i class="iconfont icon-bianji editBtn" @click="avatarClick"></i>
                </div>
                <div class="infoWrap">
                    <div class="infoTitle">昵称：</div>
                    <div>
                        <div class="infoRight form-item" v-if="!showValName">
                            <span>{{ruleForm.name}}</span>
                            <i class="iconfont icon-bianji editBtn" @click="changeShow('name')"></i>
                        </div>
                        <div class="form-item" v-else>
                            <el-input v-model="ruleForm.name" class="input-style"></el-input>
                            <el-button type="text" @click.native="submitForm('name')">保存</el-button>
                        </div>
                    </div>
                </div>
                <!-- <el-form-item label="昵称：" prop="name">
                    <div class="form-item">
                        <div class="infoRight" v-if="!showVal">
                            <span>{{ruleForm.name}}</span>
                            <i class="iconfont icon-bianji editBtn" @click="changeShow"></i>
                        </div>
                        <div v-else>
                            <el-input v-model="ruleForm.name" class="input-width"></el-input>
                            <el-button type="text" @click.native="submitForm('ruleForm')">保存</el-button>
                        </div>
                    </div>
                </el-form-item> -->

                <div class="infoWrap">
                    <div class="infoTitle">手机号：</div>
                    <div class="infoRight">{{ruleForm.mobile}}</div>
                </div>
                <div class="infoWrap">
                    <div  class="infoTitle">密码：</div>
                    <div class="infoRight">********</div>
                    <i class="iconfont icon-bianji editBtn" @click="changePwdBtnClick"></i>
                </div>
                <div class="infoWrap">
                    <div class="infoTitle">简介：</div>
                    <div>
                        <div class="infoRight form-item" v-if="!showValIntro">
                            <span>{{ruleForm.intro}}</span>
                            <i class="iconfont icon-bianji editBtn" @click="changeShow('intro')"></i>
                        </div>
                        <div class="form-item" v-else>
                            <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 5}" 
                            v-model="ruleForm.intro" class="input-style"></el-input>
                            <el-button type="text" @click.native="submitForm('intro')">保存</el-button>
                        </div>
                    </div>
                </div>
            </el-form>

            <div class="footerWrap">
                <el-button @click="handleClose">关闭</el-button>
                <!-- <el-button type="primary" class="sureBtn" @click.native="submitForm('ruleForm')">确定</el-button> -->
            </div>

            <UploadAvatar v-model="uploadImgVisible" @finish="uploadImgFinish" ></UploadAvatar>
            <ChangePwd :visible.sync="changePwdVisible" @modifyClose="modifyClose"></ChangePwd>

    </el-dialog>

</template>
  
<script>
import { isMobile, isTel, routerPush } from '@/utils'
import { Message } from "element-ui";
import { mapState } from 'vuex'
import store from '@/store'
import router from '@/router'
import Botton from "@/components/button/Button.vue"
import REQUEST from '@/api/http'
import { permissionDictionary } from '@/service/dictionary'
import UploadAvatar from '@/components/uploadImg/UploadAvatar.vue'
import ChangePwd from '@/layout/changePwd.vue'

 
export default {
    components: {Botton, UploadAvatar, ChangePwd},
    data() {
        return {
            ruleForm:{
                name:"",
                mobile:"",
                intro:"",
            },
            rules:{
                name: [
                    { required: true, message: '不能为空', trigger: 'blur' }
                ],
            },
            isEdit:false,
            id:null,
            uploadImgVisible:false,
            changePwdVisible:false,
            myInfo:{},
            showValName: false,
            showValIntro: false,
        }
    },
    props:{
        visible:Boolean
    },
    async mounted(){
    },
    watch: {
        visible(){
            if(this.visible){
                this.showValName = false
                this.showValIntro = false
                this.getUserInfo()
            }
        },
        myInfo(){
            if(this.myInfo.userCode){
                this.ruleForm.mobile = this.phoneFilter(this.myInfo.mobile)
                this.ruleForm.intro = this.phoneFilter(this.myInfo.intro)
            }else{
                this.ruleForm.mobile = ''
            }
        }
    },
    computed:{
        ...mapState(['scoket', 'openMenu', 'total', 'userInfo', 'avatar', 'notice', 'gpt3Total', 'gpt4Total', 'loginVisible', 'haveUserInfo', , 'nickName', 'afterQrcodeShow', 'topInviteBtnShow']),

        width(){
            if(isMobile()){
                return "90%"
            }
            return "700px"
        }

    },
    methods:{
        // 修改编辑框显示状态
        changeShow(e) {
            if(e==='name'){
                this.showValName = !this.showValName
            }else{
                this.showValIntro = !this.showValIntro
            }
        },
        //手机号过滤器
        phoneFilter(val) {
            let reg = /^(.{3}).*(.{4})$/;
            return val.replace(reg, "$1****$2");
        },
        init(){
            this.ruleForm.name = this.nickName
        },

        handleClose(){
            this.ruleForm.name = ''
            this.ruleForm.mobile = ''
            this.ruleForm.intro = ''
            this.$emit('update:visible', false)
        },

        async submitForm(formName){
            // this.$refs[formName].validate(async (valid)=>{
            //     if (valid) {
            //         if(!this.ruleForm.name){
            //             this.$message.error('昵称不能为空');
            //             return
            //         }
            //         var param = {}
            //         var res = await this.$https('modifyNickname', {newNickname:this.ruleForm.name})
            //         await this.$store.dispatch('loadHomeData')
            //         this.handleClose()
            //     }
            // })
            if(formName==='name'){
                if(!this.ruleForm.name){
                    Message.error('昵称不能为空');
                    return
                }
                var res = await this.$https('modifyNickname', {newNickname:this.ruleForm.name})
            }else{
                var res = await this.$https('modifyIntro', {intro:this.ruleForm.intro})
            }
            await this.$store.dispatch('loadHomeData')
            this.handleClose()
        },
        async uploadImgFinish(url){
            await this.$store.dispatch('loadHomeData')
        },
        avatarClick(){
            this.uploadImgVisible = true
        },
        modifyClose(){

        },
        changePwdBtnClick(){
            this.changePwdVisible = true
        },
        // 获取用户信息
        async getUserInfo(){
            try{
                var res = await this.$https('GET_USERINFO', {})
                if(res.data){
                    this.myInfo = res.data
                }
            }catch(e){
                // console.log(e);
            }
        },
    }
}
</script>
  
<style scoped lang="scss">

.infoWrap{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 28px 0;
    .infoTitle{
        color: #606266;
        padding-right: 16px;
        width: 109px;
        text-align: right;
    }
    .avatar{
        width: 60px;
        height: 60px;
        border-radius: 60px;
    }
    .infoRight{
        color: #666;
        padding-right: 5px;
    }
}

.footerWrap{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 40px;
}
.sureBtn{
    margin-left: 60px;
}

.editBtn{
    margin: 10px;
    width: 18px;
    height: 18px;
    cursor: pointer;
    text-align: center;
    line-height: 18px;
    color: #cacaca;
}
.editBtn:hover{
    color: #1d1b1b;
}
.form-item {
    display: flex;
    align-items: center;
}
.input-style {
    padding-right: 5px;
}
@media screen and (max-width: 900px) {

}

</style>