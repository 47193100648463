<template>
    <el-dialog :visible.sync="value" 
        :before-close="handleClose"  
        :close-on-click-modal="false"
        :width="width"
        center
        title="图片裁剪"
        append-to-body>

            <div class="wrap">

                <div class="vueCropperWrap">
                    <vueCropper 
                        ref="cropper"
                        :img="option.img"
                        :outputSize="outputSize"
                        :outputType="option.outputType"
                        :info="true"
                        :full="full"
                        :canMove="option.canMove"
                        :canMoveBox="option.canMoveBox"
                        :original="option.original"
                        :autoCrop="option.autoCrop"
                        :fixed="fixed"
                        :fixedNumber="fixedNumber"
                        :centerBox="option.centerBox"
                        :infoTrue="option.infoTrue"
                        :fixedBox="option.fixedBox"
                        :enlarge="enlarge"
                        :maxImgSize="maxImgSize"
                    ></vueCropper>
                </div>


                <el-upload
                    ref="upload"
                    :show-file-list="false"
                    :format="['jpg', 'jpeg', 'png']"
                    :max-size="2048"
                    :before-upload="handeleBeforeUpload"
                    type="drag"
                    accept="image/*"
                    action=""
                    class="avatar-uploader">
                    <el-button class="uploadBtn" type="primary" v-if="!this.option.img">上传图片</el-button>
                    <el-button class="uploadBtn" type="primary" v-if="this.option.img">重新上传</el-button>


                </el-upload>

                <div class="floor">
                    <el-button round @click="handleClose">取消</el-button>
                    <el-button @click="sureClick" type="primary" round>确定</el-button>
                </div>

            </div>


    </el-dialog> 
  </template>
  
  <script>
  import { HTTP_URI } from "@/api/api";
import REQUEST from "@/api/http";
import store from "@/store";
import axios from "axios";
import { VueCropper } from "vue-cropper";
import {isMobile, routerPush} from "../../utils/index.js"
import {compress, compressAccurately} from 'image-conversion';
import { getToken } from '@/service/login.js';


  export default {
    name: "UploadHeadImg",
    components: {
      VueCropper,
    },
    // props:["visible", "maxImgSize", "enlarge"],
    props:{
        value:{
            type:Boolean
        },
        maxImgSize:{
            type:Number,
            default:400
        },
        enlarge:{
            type:Number,
            default:1
        },
        fixedNumber:{
            typere:Array,
            default:()=>[1,1]
        },
        fixed:{
            type:Boolean,
            default:true
        },
        full:{
            type:Boolean,
            default:false
        },
        outputSize:{
            type:Number,
            default:0.5
        },
        imgType:{
            type:String,
            default:'app-icon'
        },
        fixedBox:{
            type:Boolean,
            default:false
        }
    },
    data() {
      return {
        modal1: false,
        imgSrc: "",
        option: {
          img: "", // 裁剪图片的地址
          info: true, // 裁剪框的大小信息
          outputSize: 0.5, // 裁剪生成图片的质量
          outputType: "jpeg", // 裁剪生成图片的格式
          canScale: false, // 图片是否允许滚轮缩放
          autoCrop: true, // 是否默认生成截图框
        //   autoCropWidth: 100, // 默认生成截图框宽度
        //   autoCropHeight: 100, // 默认生成截图框高度
          fixedBox: false, // 固定截图框大小 不允许改变
          fixed: true, // 是否开启截图框宽高固定比例
          fixedNumber: [1, 1], // 截图框的宽高比例
          full: false, // 是否输出原图比例的截图
          canMoveBox: true, // 截图框能否拖动
          original: false, // 上传图片按照原始比例渲染
          maxImgSize:240,
          centerBox: true, // 截图框是否被限制在图片里面
          infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        },
        fileinfo: "",
        // 防止重复提交
        loading: false,
      };
    },

    watch: {
        value(){
            if(this.value){

                this.option.img = ""

            }
        }
    },

    computed:{
        width(){
            if(isMobile()){
                return "100%"
            }
            return "600px"
        }
    },
  
    mounted() {},
  
    methods: {
      handeleBeforeUpload(file) {
        this.fileinfo = file;
        let data = window.URL.createObjectURL(new Blob([file]));
        this.option.img = data;
        this.modal1 = true;
        return false; //取消自动上传
      },
      async sureClick() {
        let that = this;
        this.$refs.cropper.getCropBlob(async (data) => {
            // console.log("cropper data", data)
            that.loading = true;
            that.$refs.upload.clearFiles();
            var compressRes = await this.compress(data)
            // console.log("compressRes", compressRes)

            let formData = new FormData();
            formData.append("file", compressRes, this.fileinfo.name);

          // let file = formData.get("file");

          // // 转为 base64
          // let reader = new FileReader();
          // reader.onload = function () {
          //   // base64结果
          //   that.imgSrc = this.result;
          //   that.loading = false;
          // };
          // reader.readAsDataURL(file);

          //   that.$emit('getFormdata', file);
          this.uploadImg(formData)
        });
      },

        uploadImg(file){

            // console.log("file", file)

            // console.log("fileinfo", this.fileinfo)

            // this.$https('UPLOAD_IMG', {file:file, imgType:"app-icon"})

            // REQUEST.POST_URL(HTTP_URI["UPLOAD_IMG"] + "?imgType=app-icon", file)

            this.$store.commit('SET_LOADING',true)

            var headers = {
                "Content-Type": "multipart/form-data",
                "token":getToken()
            }
            if(this.imgType){
                headers.imgType = this.imgType
            }
            
            if(isMobile()){
              headers['DeviceType'] = 'Mobile'
            }
            
            axios({
                method: "post",
                headers: headers,
                url: HTTP_URI.CHANGEAVATAR,
                data: file,
            }).then( (response)=> {
                // console.log(response);

                if (response.data.status == 200) {
                    
                    this.$emit("finish", response.data.data)
                    this.$message.success('上传图片成功！')

                }else{
                    this.$message.success(response.data.message)

                }
                this.$store.commit('SET_LOADING',false)

                this.handleClose()

            }).catch( (error) =>{
                console.log(error);
                this.$message.warning('上传失败，请重新上传')
                this.$store.commit('SET_LOADING',false)
                this.handleClose()

            });


            // this.$emit("finish")

        },

        handleClose(){
            this.$emit('input', false)
        },
        async compress(file){
            var res = await compressAccurately(file,200)
            return res
        }

    },
  };
  </script>
  
  <style lang="scss" scoped>

  .wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .UploadImgWrap{
    width: 200px;
    height: 200px;
  }
  .imgWrap{
    width: 100%;
    height: 100%;
    position: relative;
  }
  .opacity{
    opacity: 0;
  }
  .avatar-uploader{
    width: 100px;
    height: 40px;
    background: #45c08500;
    border-radius: 50px;
    color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    .el-upload{
        width: 100%;
        height: 100%;
    }
  }
  .myImg{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: contain;
  }


  .vueCropperWrap {
    width: 100%;
    height: 300px;
  }
  .upload-box {
    margin: 20px;
    display: inline-block;
    ::v-deep .ivu-upload.ivu-upload-drag{
      border: 1px dashed #999 !important;
    }
  }
  .upload-box,
  .upload-icon,
  .upload-img {
    width: 120px;
    height: 120px;
  }
  .upload-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }




  .avatar-uploader ::v-deep .el-upload {
    // border: 1px dashed #d9d9d9;
    // border-radius: 6px;
    // cursor: pointer;
    // position: relative;
    // overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
  }
  .avatar-uploader ::v-deep .uploadBtn{
        position: absolute;
        left: 0;
        top: 0;
  }
  .avatar-uploader ::v-deep  .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .floor{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 30px;
  }


  </style>
  
  