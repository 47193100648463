import REQUEST from '@/api/http'
import router from '@/router'
import { appModelTypes_to_modelTypes } from '@/utils'
import Vue from 'vue'
import Vuex from 'vuex'

export default {
    state: ()=>{
        return {
            curEditApp:null
        }
    },
    mutations:{


        SET_curEditApp: (state, data) => {
            state.curEditApp = data
        },



        
    },
    actions:{
        loadAppDetail: ({commit}, {appVersionId})=>{
            return new Promise(async (resolve, reject)=>{
                
                
                var res = await this.$https.get('CREATR_getApp', {appVersionId:appVersionId})
                appModelTypes_to_modelTypes(res)
                commit('SET_curEditApp', res.data)

                // commit('SET_TOTAL', res.data.remainingTimes)
                // commit('SET_GPT3_TOTAL', res.data.remainingTimesGpt3)
                // commit('SET_GPT4_TOTAL', res.data.remainingTimesGpt4)
                // commit('SET_AVATAR', res.data.avatar)


            })
        }
    }
}