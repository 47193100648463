<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-05-23 15:06:49
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-23 15:09:50
 * @FilePath: /AIGo.work/src/layout/gzh.vue
-->
<template>
  <div>
    <img
      src="../assets/erweima.jpg"
      style="width: 100%;">
    <div
      style="text-align: right;">
      <el-button
        @click="$emit('chanePage')"
        type="primary">关闭</el-button>
    </div>

  </div>
</template>

<script>
export default {}
</script>

<style>
</style>