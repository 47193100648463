export var scroll = {
    bind: function (el, binding, vnode) {
        function scrollHandler(e){
                binding.value({scrollTop:el.scrollTop}); // 执行传入的回调函数
        }
        el.__vueScrollHandler__ = scrollHandler;
        el.addEventListener('scroll', scrollHandler);
    },
    unbind(el, binding) {    // 解除事件监听
        el.removeEventListener('scroll', el.__vueScrollHandler__);
        delete el.__vueScrollHandler__;
    }
}