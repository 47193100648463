<template>
    <el-dialog :visible.sync="visible" 
        :width="width"
        :close-on-click-modal="false"
        center
        title=""
        append-to-body>


            <div class="text">是否付款成功了？</div>
            <div class="wrap1">
                <a href="" class="btn btnNo">否</a>
                <a href="" class="btn btnYes">是</a>
            </div>
            


    </el-dialog>
  </template>
  
  <script>
  import { mapState } from 'vuex'
  import 'highlight.js/styles/devibeans.css'
  import { HTTP_URI } from '@/api/api'
import { isMobile } from '@/utils'
  export default {
    data() {
      return {


      }
    },

    props:{
        visible:Boolean
    },
    
    components:{},
    async mounted() {
        

      
    },
    watch: {
        visible(){
            if(this.visible){

                this.isFinish = false


            }else{
                
            }
        }
    },
    computed:{

        width(){
            if(isMobile()){
                return "90%"
            }
            return "400px"
        }

        

    },
  
    methods: {
        

        handleClose(){
            this.$emit('update:visible', false)
        }

    }
  }
  </script>
  <style lang="scss" scoped>

.wrap1{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.text{
    text-align: center;
    font-size: 18px;
    padding: 20px 0 40px;
    
}
.btn{
    width: 100px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    border-radius: 6px;
}
.btnNo{
    border: 1px solid #eee;
}
.btnYes{
    background: rgb(143, 29, 226);
    color: #fff;
}

@media screen and (max-width: 900px){


    
    
}

  </style>